<div class="quote-section">
    <div>
        <h2 class="cpq-page-sub-header textcapitalize margin-top_30">Quotes</h2>
    </div>
    <div >
        <div class="container-w">
            <div class="form-group" class="datePicker" *ngIf="!isGuest">
                <input class="calendarInput" placeholder="Start Date - End Date"
                    placement="left"
                    (bsValueChange)="onQuoteRangeChange($event)" 
                    bsDaterangepicker 
                    [minDate]="minDate" 
                    [maxDate]="maxDate"
                    [(bsValue)]="daterangepickerModel"
                    #drp="bsDaterangepicker"
                    [bsConfig]="{containerClass:'theme-orange', dateInputFormat: 'MM-DD-YYYY',  adaptivePosition: true }">
                <i class="fa fa-calendar faCalender" (click)="drp.toggle()"></i>
            </div>
            <div *ngIf="dateSelectionError" class="error">
               <p> {{dateSelectionErrorText}}</p>
            </div>
        </div>
    </div>
</div>
<!--If  Quote search will be needed in funture-->
<!-- <div fxLayout="row">
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Quote Search..">
  </mat-form-field>
</div> -->
<div>
    <div class="mat-elevation-z8 quote-margin-bottom" *ngIf="!noQuotesItems && !isGuest">
        <div class="quote-table">
            <mat-table [dataSource]="quotes" matSort>

                <ng-container matColumnDef="ModifiedTime">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="quote-table-header textcapitalize">
                        Generated
                        Date
                    </mat-header-cell>
                    <mat-cell *matCellDef="let quote" data-label="generated date :">
                        <div>
                            <div class="quote-genrated-date">{{quote.ModifiedTime | date: 'MM/dd/yyyy'}}</div>
                            <div class="quote-expires-date"> Expires on {{quote.ExpirationTime |date: 'MMM dd, yyyy'}}
                            </div>
                        </div>
                    </mat-cell>
                </ng-container>


                <ng-container matColumnDef="BudgetaryNumber">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="quote-table-header"> Quote
                        Number </mat-header-cell>
                    <mat-cell *matCellDef="let quote" data-label="quote number :" class="quote-number">
                        {{quote.BudgetaryNumber}}
                    </mat-cell>
                </ng-container>


                <ng-container matColumnDef="Name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="quote-table-header"> Quote Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let quote" data-label="quote name :"> {{quote.Name}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Note">
                    <mat-header-cell *matHeaderCellDef class="quote-table-header"> Notes
                    </mat-header-cell>
                    <mat-cell *matCellDef="let quote" data-label="Notes :"> {{quote.Note}} </mat-cell>
                </ng-container>


                <ng-container matColumnDef="TotalAmount">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="quote-table-header"> Net Totals
                    </mat-header-cell>
                    <mat-cell *matCellDef="let quote" [style.color]="quote.cost" data-label="Net Totals :"
                        class="quote-nettotal">
                        <span *ngIf="quote.TotalAmount != undefined; else elseBlock">
                            {{quote.TotalAmount | currency}}
                        </span>
                        <ng-template #elseBlock>$ <span class="hidden-price">{{HIDDEN_PRICE}}</span></ng-template>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="ExpirationDate">
                    <mat-header-cell *matHeaderCellDef class="quote-table-header">
                    </mat-header-cell>
                    <mat-cell *matCellDef="let quote" [style.color]="quote.ExpirationTime" data-label="Download :">
                        <a (click)="quoteProposalUrl(quote.Id)" target="_blank"><img
                                src="../../../assets/images/download-1.svg" class="proposaldownload"></a>
                    </mat-cell>
                </ng-container>

                <mat-header-row class="quote-header-row" *matHeaderRowDef="displayedColumnsForQuote"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumnsForQuote;">
                </mat-row>

            </mat-table>
            <!-- Loader -->
            <mat-card *ngIf="isQuotesLoading" style="display: flex; justify-content: center; align-items: center">
                <mat-progress-spinner color="primary" mode="indeterminate">
                </mat-progress-spinner>
            </mat-card>
        </div>
        <!-- Paginator  -->
        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
    <!-- No generated quotes-->
    <div class="container-color margin-top_5 container_minheight quote-margin-bottom outer_cart"
        *ngIf="noQuotesItems && !isGuest">

        <div class="inner_cart">
            <div>
                <h1 class="no-quote-config">No generated quotes</h1>
            </div>
            <div>
                <span><button mat-flat-button class="landing_page_button-color textcapitalize"
                        (click)="navigateToPreSelects()">CONFIGURE</button> </span>
            </div>
        </div>
    </div>
    <!-- Request access to Quotes-->
    <div class="container-color margin-top_5 container_minheight quote-margin-bottom outer_cart"
        *ngIf="isGuest">
        <div class="inner_cart">
            <div>
                <h1 class="action-sentence">Please <a class="request-access-link" (click)="openHubspotRequest()">request
                        access</a> to generate
                    quotes.</h1>
            </div>
            <div>
                <span class="">Access allows you to create and download quotes</span>
            </div>
        </div>
    </div>
    <!------------------------------------->
</div>