import { Component, AfterViewChecked } from '@angular/core';
import { Location } from '@angular/common';
import { HubspotDialogComponent, HubspotForms } from '../../../app/content/hubspot-modal/hubspot-modal.component';
import { GuestConfigService } from '@cpq-app/adminstration/guest-config/guest-config.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements AfterViewChecked {
  isGuest: false;
  isSalesForceFlow = false;

  constructor(private location: Location, public dialog: MatDialog, private guestService: GuestConfigService,) { }

  navigateBack() {
    this.location.back();
  }

  ngAfterViewChecked() {
    const loggedInUser = this.guestService.getGuestUserDetails();
    this.isGuest = loggedInUser.isGuest;
    this.isSalesForceFlow = sessionStorage.getItem('salesforceToken') ? true : false;
  }
  /**
  * Opens a material modal to show Hubspot forms
  */
  openHubspotSupport() {
    this.dialog.open(HubspotDialogComponent, {
      data: {
        formName: HubspotForms.ContactSupport
      }
    });
  }

  /**
  * Opens a material modal to show Hubspot forms
  */
  openHubspotSales() {
    this.dialog.open(HubspotDialogComponent, {
      data: {
        formName: HubspotForms.ContactSales
      }
    });
  }
}
