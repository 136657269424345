import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';

import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { CartService, CpqObjectUpdateResult, CpqObjects, CpqOpportunity, CpqQuote, CrmExportStatus, userRole } from '@cpq-app/services/cart.service';
import { LoginService } from '@cpq-app/services/login.service';
import { SalesforceProxyService, sfdcObjectType, SfdcOpportunity } from '@cpq-app/services/salesforce.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { IframeDialogComponent } from '../iframe-modal/iframe-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditQuoteComponent } from "../edit-quote/edit-quote.component";
import { Opportunity, Quote } from '@cpq-app/models/twg-interfaces';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit, OnChanges, OnDestroy {
  showChildren = false;
  private commonSubscriptions = new Subscription();
  private quoteSubscription = new Subscription();
  searchString = '';
  opportunityId: string;
  quoteID: string;
  LOGIN_SPINNER = 'login';
  //TODO : set flag to see internal use and external user flow
  internalUser;
  opportunity;
  salesForceOppId;
  ExternalId;
  quoteSubmittedStatus;
  isSalesForceFlow = false;
  isPrimaryQuote;

  constructor(
    private cartService: CartService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private salesforce: SalesforceProxyService,
    private loginService: LoginService,
    private activeRoute: ActivatedRoute,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    //TODO : set value
    this.spinner.hide().then(() => {
      this.spinner.show(this.LOGIN_SPINNER);
    });
    this.ExternalId = this.activeRoute.snapshot.params['sfdcOpportunityId'];
    this.isSalesForceFlow = this.ExternalId ? true : false;
    sessionStorage.setItem('ExternalId', this.ExternalId);
    if(sessionStorage.getItem('loggedInUserName') == null || sessionStorage.getItem('loggedInUserName') == 'null' || sessionStorage.getItem('loggedInUserName') == 'undefined'){
      const loggedIn$ = this.loginService.loginUser();
      const subscription = loggedIn$.subscribe(
        resp => {
          sessionStorage.setItem('loggedInUserName', resp.Username);
          this.getInitialDetailsOnLoad();
        }
      );
      this.commonSubscriptions.add(subscription);
    }else{
      this.getInitialDetailsOnLoad();
    }

  }

  getInitialDetailsOnLoad(){
    if (sessionStorage.getItem('userRole') === userRole.internal) {
      this.internalUser = true;
    }
    if (this.internalUser && this.ExternalId) {
      if (this.ExternalId) {
        this.getOpportunityBasedOnId(this.ExternalId);
      } else {
        this.spinner.hide(this.LOGIN_SPINNER);
      }
    } else {
      this.fetchLatestOpportunityID();
    }
  }

  getOpportunityDetail() {
    const subscription = this.cartService.getCpqObjects(CpqObjects.Opportunity, this.opportunityId)
      .subscribe({
        next: (opportunity: any) => {
          console.log('INTERNAL USER FLOW: opportunity', opportunity)
          this.opportunity = opportunity;
          this.spinner.hide();
          this.spinner.hide(this.LOGIN_SPINNER);
        },
        error: err => {
          this.spinner.hide();
          this.spinner.hide(this.LOGIN_SPINNER);
          this.toastr.error(
            'There is fatal error while fetching revision', 'Error', {
            disableTimeOut: true,
            closeButton: true
          }
          );
        }
      })
    this.commonSubscriptions.add(subscription);
  }

  ngOnChanges(changes?: SimpleChanges): void {

  }

  onUpdate(event) {
    this.opportunity = event;
  }

  createOpportunity(externalId?, opportunityName?, account?, contact?) {
    let payload: any = {
      Name: opportunityName ? opportunityName : 'New Opportunity',
      CrmId: externalId,
      CrmExportStatus: CrmExportStatus.NotExported,
    };
    if(account) {
      payload.AccountId = account.Id
    }
    if(contact){
      payload.SourceSalesforce__c = contact;
    }
    const subscription$ = this.cartService.createObject(CpqObjects.Opportunity, payload).subscribe(
      (results: Opportunity) => {
        this.opportunityId = results.Id;
        this.cartService.store('opportunityId', this.opportunityId);
        this.createQuote(opportunityName);
      },
      err => {
        // FIXME: we need to handle this
        this.toastr.error(
          'There was a fatal error while creating opportunity id'
        );
        this.spinner.hide();
      }
    );
    this.commonSubscriptions.add(subscription$);
  }


  createCpqOpportunity(payload) {
    const subscription$ = this.cartService.createObject(CpqObjects.Opportunity, payload).subscribe(
      results => {
        this.opportunityId = results.Id;
        this.cartService.store('opportunityId', this.opportunityId);
        this.createQuote();
      },
      err => {
        // FIXME: we need to handle this
        this.toastr.error(
          'There was a fatal error while creating opportunity id'
        );
        this.spinner.hide();
      }
    );
    this.commonSubscriptions.add(subscription$);
  }

  createQuote(opportunityName?) {
    this.quoteSubscription.unsubscribe();
    const subscription$ = this.cartService
      .createQuoteId(this.opportunityId, opportunityName)
      .subscribe(
        (quote: Quote) => {
          this.quoteID = quote.Id;
          this.cartService.store('quoteId', this.quoteID);
          console.log(`Created Quote ${this.quoteID}`);
          this.showChildren = this.quoteID ? true : false;
          if (this.internalUser) {
            this.router.navigate([
              // FIXME: Handle promise's failure to navigate
              '/products/selection',
              this.opportunityId,
              this.quoteID,
              ''
            ]);
          }
        },
        err => {
          // FIXME: we need to handle this
          this.toastr.error('There was a fatal error while creating quote id');
          this.spinner.hide();
        }
      );
    this.commonSubscriptions.add(subscription$);
  }

  createQuoteWithData(params) {
    this.quoteSubscription.unsubscribe();
    const subscription$ = this.cartService
      .createQuoteWithData(params, this.opportunityId)
      .subscribe(
        quote => {
          this.quoteID = quote.Id;
          this.cartService.store('quoteId', this.quoteID);
          console.log(`Created Quote ${this.quoteID}`);
          this.cartService
            .updateObjectById(CpqObjects.Opportunities, this.opportunityId, {
              PrimaryQuoteId: this.quoteID,
            }).subscribe();
          this.showChildren = this.quoteID ? true : false;
          if (this.internalUser) {
            this.router.navigate([
              // FIXME: Handle promise's failure to navigate
              '/products/selection',
              this.opportunityId,
              this.quoteID,
              ''
            ]);
          }
        },
        err => {
          // FIXME: we need to handle this
          this.toastr.error('There was a fatal error while creating quote id');
          this.spinner.hide();
        }
      );
    this.commonSubscriptions.add(subscription$);
  }

  fetchLatestOpportunityID() {
    this.cartService.getMostRecentOpportunity().subscribe(opportunity => {
      this.opportunity = opportunity;
      if (!opportunity.Id) {
        this.createOpportunity();
      } else {
        this.opportunityId = opportunity.Id
        if (this.internalUser) {
          this.getOpportunityDetail();
        }
        else {
          this.quoteID = opportunity.PrimaryQuoteId;
          this.showChildren = this.quoteID ? true : false;
          if (!opportunity?.PrimaryQuoteId) { // fix me later
            this.createQuote();
          }
          this.spinner.hide();
          this.spinner.hide(this.LOGIN_SPINNER);
        }
        // this.showChildComponents();
      }
    }, err => {
      console.log(err);
      this.spinner.hide();
      this.spinner.hide(this.LOGIN_SPINNER);
    });
  }

  showChildComponents() {
    this.quoteSubscription = this.cartService.publicationForQuoteWithProducts(this.quoteID).subscribe(quote => {
      if (CpqQuote.checkIsClosed(quote)) {
        this.quoteSubscription.unsubscribe();
        console.log(`%cChanges to Landing Page`, 'color: green;');
        this.fetchLatestOpportunityID();
      }
    });
    this.showChildren = true;
    this.cartService.remove('GUID');
    this.cartService.remove('product');
    this.spinner.hide(this.LOGIN_SPINNER);
  }

  ngOnDestroy() {
    this.commonSubscriptions.unsubscribe();
  }

  loadCartDetails(eventData) {
    this.quoteID = eventData.Id;
    this.opportunityId = eventData.OpportunityId;
    this.isPrimaryQuote = eventData.IsPrimary;
    this.showChildren = true;
  }

  startNewConfiguration() {
    this.spinner.show();
    this.router.navigate([
      // FIXME: Handle promise's failure to navigate
      '/products/selection',
      this.opportunityId,
      this.quoteID,
    ]);
  }

  searchProducts() {
    const rex = /^[a-zA-Z0-9_ ]*$ |([\w ]+)/;
    const linkRegex = /(?:https?|ftp):\/\/[^\s/$.?#].[^\s]*/;
    if (linkRegex.test(this.searchString) || !rex.test(this.searchString.trim())) {
      this.toastr.error('Please enter valid search text');
      return;
    }
    this.spinner.show();
    this.cartService.createObject(CpqObjects.QuoteLine, {
      "ParentQuoteId": this.quoteID,
      "RootQuoteId": this.quoteID
    })
      .subscribe({
        next: (quoteLine: CpqObjectUpdateResult) => {
          this.spinner.hide();
          this.router.navigate([
            '/products/results',
            this.opportunityId,
            this.quoteID,
            quoteLine?.Id
          ], { queryParams: { keyword: this.searchString } });
        },
        error: (err) => {
          this.spinner.hide();
        }
      });
  }

  startProductSearchConfiguration() {
    const rex = /^[a-zA-Z0-9_ ]*$ |([\w ]+)/;
    if (!rex.test(this.searchString.trim())) {
      this.toastr.error('Please enter valid search text');
      return;
    }
    this.spinner.show();

    const subscription$ = this.cartService
      .openConfigurationDefaultDataset(this.opportunityId, this.quoteID)
      .subscribe(configData => {
        this.router.navigate(
          [
            '/products/results',
            this.opportunityId,
            this.quoteID,
            configData.configId
          ],
          { queryParams: { search: this.searchString } }
        );
      }, err => {
        // FIXME: we need to handle this
        this.toastr.error(
          'There was a fatal error while starting a configuration'
        );
        this.spinner.hide();
      });

    // this.commonSubscriptions.add(subscription$);
  }

  /**
   * Opens a material modal
   * @param destination as `string`
   */
  openModal(destination?: string) {
    let url: string;
    switch (destination) {
      case 'video':
        url = 'https://www.dovertwg.com/cpq-how-to/';
        break;

      case 'faq':
      default:
        url = 'https://www.dovertwg.com/cpq-faq/';
        break;
    }

    this.dialog.open(IframeDialogComponent, {
      data: {
        url
      },
      width: '80%',
      height: '80%',
    });
  }

  loadFreshOpportunity(event) {
    if(event) {
      if (!this.ExternalId) {
        this.fetchLatestOpportunityID();
      } else {
        this.getAccossiatedQuotes(this.opportunityId);
        this.quoteID = this.opportunity.PrimaryQuoteId;
      }
    } else {
      this.getOpportunityDetail();
    }
  }

  getOpportunityBasedOnId(Id) {
    const getAllOpportunity = this.cartService.getCpqObjects(CpqObjects.Opportunities);
    const getSalesForceOpportunity = this.salesforce.getSfdcObjectById(sfdcObjectType.OPPORTUNITY, Id);
    const getAllCPQAccounts = this.cartService.getCpqObjects<any>(CpqObjects.Accounts);
    forkJoin([
      getAllOpportunity, getSalesForceOpportunity, getAllCPQAccounts
    ]).subscribe((res: Opportunity[]) => {
      const allOpportunity: any = res[0];
      const salesforceopprtunityDetails = res[1];
      const cpqAccounts: any = res[2];
      
      const opportunityDetails = allOpportunity.filter((ele: any) => ele.CrmId === Id);
      const selectedAccount = cpqAccounts.find(account => account.ExternalId == salesforceopprtunityDetails?.Account?.Id);
      const salesforceContact = {
        Contact : {
          Name: salesforceopprtunityDetails?.Contact__r?.Name ? salesforceopprtunityDetails.Contact__r.Name: "",
          Email: salesforceopprtunityDetails?.Contact__r?.Email ? salesforceopprtunityDetails.Contact__r.Email: "",
          Phone: salesforceopprtunityDetails?.Contact__r?.Phone ? salesforceopprtunityDetails.Contact__r.Phone: ""
        }
      }
      if (opportunityDetails?.length) {
        const oppId = opportunityDetails[0].Id;
        if(opportunityDetails[0].CrmExportStatus === CrmExportStatus.NotExported && 
          (opportunityDetails[0].Name !== salesforceopprtunityDetails.Name || 
            opportunityDetails[0].AccountId !== selectedAccount?.Id)) {
          const payload = {
            Name: salesforceopprtunityDetails.Name,
            AccountId: selectedAccount?.Id,
            SourceSalesforce__c: salesforceContact
          }
          this.cartService.updateObjectById(CpqObjects.Opportunities, oppId, payload).subscribe(res => {
            this.opportunityId = res.Id;
            this.opportunity = res;
            this.opportunity.Quotes = res['Quotes'];
          });
        } else {
          this.opportunityId = opportunityDetails[0].Id;
          this.opportunity = opportunityDetails[0];
        }
      } else {
        this.createOpportunity(Id, salesforceopprtunityDetails?.Name, selectedAccount, salesforceContact);
      }
      //this.opportunityId = opportunityDetails?.length ? opportunityDetails[0]['Id'] : this.createOpportunity(Id);
      // this.getOpportunityDetail();
      this.quoteSubmittedStatus = this.opportunity?.CrmExportStatus === CrmExportStatus.Completed ? true : false;
      this.getAccossiatedQuotes(this.opportunityId);
      this.quoteID = this.opportunity?.PrimaryQuoteId;
      this.showChildren = this.quoteID ? true : false;
      this.spinner.hide(this.LOGIN_SPINNER);
    }, err => {
      this.spinner.hide(this.LOGIN_SPINNER);
    })
  }

  getAccossiatedQuotes(oppId) {
    this.spinner.show();
    this.cartService.getCpqObjects(CpqObjects.Opportunity, oppId).subscribe(res => {
      if(this.opportunity) {
        this.opportunity.Quotes = res['Quotes'];
      }
      this.quoteSubmittedStatus = res['CrmExportStatus'] === CrmExportStatus.Completed ? true : false;
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      console.log(err);

    })
  }

  createNewQuote() {
    const instance = this.modalService.open(EditQuoteComponent, {
      size: "xl",
    });

    instance.componentInstance.type = 'create';
    instance.result.then(
      (result) => {
        if (result && result.success) {
          this.createQuoteWithData(result.data);
        }
      },
      (dismiss) => { }
    );
  }


  navigateToHome() {
    const oppId = sessionStorage.getItem('ExternalId');
    if (oppId !== 'undefined') {
      this.router.navigateByUrl(`opportunity/${oppId}`);
    } else {
      this.router.navigateByUrl('/')
    }
  }
}
