<h2 class="cpq-page-sub-header textcapitalize margin-top_30">Cart <span
        *ngIf="quote?.Lines?.length">({{quote?.Lines?.length || 0}}
        products)</span></h2>
<!-- Has Configurations-->
<div class="container-color padding_15 d-flex flex-column mb-3" *ngIf="quote?.Lines?.length">
    <div class="innercontainer_color landing-page_CartHeight d-flex col-md-12 p-0">
        <div class="cart-product d-flex flex-column w-100">
            <div *ngFor="let quoteLine of quote.Lines trackBy:trackByQuoteLineId" class="padding_5">
                <mat-card class="mat-elevation-z1 card-no-collapse" layout="row">
                    <div class="d-flex flex-direction-column card-item">
                        <div class="d-flex align-items-center" [ngClass]="{'disabled': quoteSubmittedStatus}">
                            <a (click)="onModelCodeClick(quoteLine.Id)">
                                <img *ngIf="!quoteLine?.ProductNumber || quoteLine?.RequiresUpdate__c || quoteLine?.ProductNumber === '' || quoteLine?.ProductNumber === null"
                                    class="reconfig_Icon" src="assets/images/reconfiguration.svg">
                                <img [src]="quoteLine?.SelectedProduct?.Image" class="product-img" />
                            </a>
                            <div class="product-details">
                                <div class="product-name">{{quoteLine?.SelectedProduct?.Name}}</div>
                                <div class="model-code-header">Model Code:</div>
                                <!-- <div class="model-code-text">{{quoteLine.ProductId}}</div> -->
                                <a href="javascript: void(0);" (click)="onModelCodeClick(quoteLine.Id)">
                                    <div class="model-code-text">{{quoteLine?.ProductNumber}}</div>
                                    <!-- {{getProductModelCode(quoteLine.ProductId)}}</div> -->
                                </a>
                            </div>
                        </div>
                        <div class="cart-text">
                            <span class="note">Notes:</span><span>{{quoteLine?.Notes}}</span>
                        </div>
                        <div class="quantity-price">
                            <div class="cart-text">
                                <div class="quantity-test cost-text"> Quantity</div>
                                <div class="cost"><input class="landing-page_Quantity" type="number" matInput [(ngModel)]="quoteLine.Quantity"
                                        (focusout)="updateQuantity(quoteLine)"
                                        (input) = "change($event)" name="productQuantity"
                                        #productQuantity="ngModel" min="1" step="1" pattern="[0-9]*"  required
                                        [ngModelOptions]="{ standalone: true }" [disabled]="quoteSubmittedStatus" />
                                    <br>
                                    <span class="text-danger" *ngIf="productQuantity.errors?.required">Required
                                        field</span>
                                    <span class="text-danger"
                                        *ngIf="isQuantityOutOfRange(quoteLine.Quantity, quoteLine?.SelectedProduct?.MinQuantity) && !productQuantity.errors?.required">
                                        Minimum required quantity is {{quoteLine?.SelectedProduct?.MinQuantity > 0 ?
                                        quoteLine?.SelectedProduct?.MinQuantity
                                        : 1 }} and
                                        maximum is 99'999
                                    </span>
                                </div>
                            </div>
                            <div class="cart-text">
                                <div class="cost-text">List Price</div>
                                <div class="cost">
                                    <span *ngIf="quoteLine.ListPrice != undefined; else elseBlock">
                                        <!-- {{ getUnitPrice(quoteLine.ProductId) | currency}} -->
                                        {{quoteLine.ListPrice | currency}}
                                    </span>
                                    <ng-template #elseBlock>$ <span class="hidden-price">{{HIDDEN_PRICE}}</span>
                                    </ng-template>
                                </div>
                            </div>
                            <div class="cart-text">
                                <div class="cost-text">Net Price</div>
                                <div class="cost">
                                    <span *ngIf="quoteLine.UnitSellingPrice != undefined; else elseBlock">
                                        <!-- {{ getListPrice(quoteLine.ProductId) | currency}} -->
                                        {{quoteLine.UnitSellingPrice | currency}}
                                    </span>
                                    <ng-template #elseBlock>$ <span class="hidden-price">{{HIDDEN_PRICE}}</span>
                                    </ng-template>
                                </div>
                            </div>
                            <div class="cart-text">
                                <div class="cost-text">Price</div>
                                <div class="cost">
                                    <span *ngIf="quoteLine.TotalSellingPrice != undefined; else elseBlock">
                                        {{quoteLine.TotalSellingPrice | currency}}
                                    </span>
                                    <ng-template #elseBlock>$ <span class="hidden-price">{{HIDDEN_PRICE}}</span>
                                    </ng-template>
                                </div>
                            </div>
                            <div class="product-tooltip" [ngClass]="{'disabled': quoteSubmittedStatus}">
                                <a href="javascript: void(0);" (click)="deleteProduct(quoteLine.Id)"><img
                                        class="action-icon" src="assets/images/delete-1.svg"></a>
                                <span class="product_tooltiptext">Delete Product</span>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
    <div class="container-color cart-action">
        <div>
            <button *ngIf="isGuest" mat-stroked-button color="primary"
                class="textcapitalize cpq-button-text btn-request-access" (click)="openHubspotRequest()">REQUEST
                ACCESS</button>
            <button *ngIf="!isGuest" mat-flat-button color="accent"
                class="textcapitalize cpq-button-text btn-submit-quote"
                [disabled]="isSubmitDisabled() || quoteSubmittedStatus || !isPrimaryQuote || isQuoteRequiresUpdate"
                (click)="openSubmitQuoteDialog()">SUBMIT QUOTE</button>
        </div>
        <div class="date_sec">
            <div *ngIf="!isGuest" class="ancillary-text">
                Expires on {{quote.ExpirationTime|date: 'MMM dd, yyyy'}}
            </div>
            <div *ngIf="isGuest" class="ancillary-text">
                Please request access to view and submit quotes.
            </div>
        </div>
        <div class="d-flex align-items-center">
            <div class="container-w">
                <div class="total-cost-text">Total Price</div>
                <div class="total-cost">
                    <span *ngIf="quote.TotalAmount != undefined; else elseBlock">
                        {{quote.TotalAmount | currency}}
                    </span>
                    <ng-template #elseBlock>$&nbsp;<span class="hidden-price">{{HIDDEN_PRICE}}</span></ng-template>
                </div>
            </div>
            <div
                [ngClass]="{'hidden-icon': isGuest || !quote.Proposals, 'disabled': isSubmitDisabled()|| isQuoteRequiresUpdate}">
                <!-- <a (click)="quoteProposalUrl(quote.Id)" target="_blank"><img src="../../../assets/images/download-1.svg"
                    class="proposaldownload action-icon"></a> -->
                <a (click)="documentGenerationModel(quote.Id)" target="_blank"><img
                        src="../../../assets/images/download-1.svg" class="proposaldownload action-icon"></a>
            </div>
        </div>
    </div>
    <div *ngIf="isQuoteRequiresUpdate" class="reconfiguration_Text"><img class="r-img"
            src="../../../assets/images/info.svg"> Some products needs reconfiguration, please click on <img
            class="reconfig_Icon" src="../../../assets/images/reconfiguration.svg"> to review and save configuration.
    </div>
</div>

<!-- No saved Configuration-->
<div class="container-color margin-top_5 container_minheight outer_cart mb-3" *ngIf="!quote?.Lines?.length">
    <div class="inner_cart">
        <div>
            <h1 class="no-saved-config">No saved configurations</h1>
        </div>
        <div>
            <span><button mat-flat-button class="landing_page_button-color textcapitalize"
                    (click)="navigateToPreSelects()">CONFIGURE</button> </span>
        </div>
    </div>
</div>