import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MSALService } from './msal.service';
import { AppConstant } from '../constants/app-constants';
import { environment } from '../../../environments/environment';

import { Observable } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  /**
   *
   */
  constructor(private http: HttpClient, private msalService: MSALService) {}

  getPermissions(userRole: string) {
    if(userRole == 'Admin')
    {
    return this.http.get(
      `${environment.B2CConfigs.BackendURL}/admin/auth`
    );
  }
  }

  
  getTemplatePermissions(permissions: any[]) {
    const permissionArray = {
      CREATE: true,
      READ: true,
      UPDATE: true,
      DELETE: true,
    };
    if (permissions !== null) {
      permissionArray['CREATE'] = permissions.some(
        (permission: string) => permission === AppConstant.actions.Create
      );
      permissionArray['READ'] = permissions.some(
        (permission: string) => permission === AppConstant.actions.Read
      );
      permissionArray['UPDATE'] = permissions.some(
        (permission: string) => permission === AppConstant.actions.Update
      );
      permissionArray['DELETE'] = permissions.some(
        (permission: string) => permission === AppConstant.actions.Delete
      );
    }
    return permissionArray;
  }
}
