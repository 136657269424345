import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@cpq-environments/environment';
import { root } from 'rxjs/internal/util/root';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GuestConfigService {
  constructor(private http: HttpClient) {}

  private backendUrl = environment.B2CConfigs.BackendURL;

  getConfig() {
    return this.http.get(`${this.backendUrl}/guestConfig/get`);
  }

  createConfig = (config) => {
    return this.http.post(
      `${this.backendUrl}/guestConfig/register`,
      config
    );
  };

  updateConfig = (configId,config) => {
    return this.http.put(
      `${this.backendUrl}/guestConfig/update/${configId}`,
      config
    );
  };

  deleteConfig(configId) {
    return this.http.delete(`${this.backendUrl}/guestConfig/delete/${configId}`);
  }

  setGuestUserDetails(userData) {
    if (
      userData === null ||
      typeof userData === typeof undefined ||
      typeof userData['userRole'] === typeof undefined
    ) {
      sessionStorage.setItem('isGuest', 'false');
      sessionStorage.setItem('guestEmail', '');
    } else {
      if(userData['userRole'] === 'Guest') {
        sessionStorage.setItem('isGuest', 'true');
        sessionStorage.setItem('guestEmail', userData['Username']);
      } else {
        sessionStorage.setItem('isGuest', 'false');
        sessionStorage.setItem('guestEmail', '');
      }
    }
  }

  getGuestUserDetails() {
    let Guest = {
      isGuest: JSON.parse(sessionStorage.getItem('isGuest')),
      guestEmail: sessionStorage.getItem('guestEmail')
    }
    return Guest;
  }
}
