import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";

import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { CartService, CpqObjects } from "@cpq-app/services/cart.service";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { EditQuoteComponent } from "../edit-quote/edit-quote.component";
import { ProductService } from "@cpq-app/services/product.service";
import { switchMap } from "rxjs/operators";
import { Opportunity, Quote } from "@cpq-app/models/twg-interfaces";
import { DocGenService } from "../doc-gen-modal/doc-gen.service";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-internal-user-quote",
  templateUrl: "./internal-user-quote.component.html",
  styleUrls: ["./internal-user-quote.component.scss"],
})
export class InternalUserQuoteComponent implements OnInit, OnChanges, OnDestroy {
  private commonSubscriptions = new Subscription();
  dataSource;
  colDefinition;
  displayedColumns;
  @Input() quote;
  @Input() quoteSubmittedStatus: boolean;
  @Input() opportunityName = ''
  dialogRef;
  quoteDetail = [];
  disable= false;
  @Output() loadCartDetails? = new EventEmitter();
  @ViewChild("ConfirmDialog") ConfirmDialog: TemplateRef<any>;
  @ViewChild("InformationDialog") InformationDialog: TemplateRef<any>;
  ngOnInit(): void {
    this.cartService.reConfiguration.subscribe((disable:boolean) => {
      this.disable = disable;
      this.colDefinition = this.columnDefinitionFactory();
      this.dataSource = new MatTableDataSource<any>(this.quoteDetail);
    })
  }

  constructor(
    public dialog: MatDialog,
    private cartService: CartService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private productService: ProductService,
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private datePipe: DatePipe,
    private docGenService: DocGenService
  ) { }

  columnDefinitionFactory(): any {
    const colData =   [
      {
        field: "selected",
        header: "Selected",
        fieldType: "radio",
        visibility: true,
        sortable: false,
        filterable: false,
        value: (project: any) => project?.Selected,
      },
      {
        field: "name",
        header: "Quote Name",
        visibility: true,
        sortable: false,
        filterable: false,
        value: (project: any) => project?.Name,
      },
      {
        field: "note",
        header: "Note",
        visibility: true,
        sortable: false,
        filterable: false,
        value: (project: any) => project?.Note,
      },
      {
        field: "isPrimary",
        header: "Primary",
        visibility: true,
        sortable: false,
        filterable: false,
        value: (project: any) => Boolean(project?.IsPrimary) ? 'Yes' : 'No',
      },
      {
        fieldType: "actions",
        field: "actions",
        header: "Actions",
        visibility: true,
        value: (record) => [
          {
            name: 'edit',
            disable: false
          },
          {
            name: 'copy',
            disable: false
          },
          {
            name: 'delete',
            disable: false
          },
          {
            name: 'download',
            disable: this.disable
          }
        ]
      },
    ];
    this.displayedColumns = colData.map((ele) => ele.header);
    return colData;
  }

  edit(record: any) {
    this.editQuote(record);
  }

  editQuote(record) {
    const instance = this.modalService.open(EditQuoteComponent, {
      size: "xl",
    });
    instance.componentInstance.quote = record;
    instance.result.then(
      (result: { success: boolean, data: (Opportunity | Quote) }) => {
        if (result && result.success) {
          if (result.data.hasOwnProperty('Quotes')) {
            this.quoteDetail = result.data['Quotes'];
            this.setQuoteDetail();
          } else {
            const index = this.quoteDetail.findIndex(item => item.Id === result.data.Id);
            if (index !== -1) {
              this.quoteDetail[index] = result.data;
              this.setQuoteDetail()
            }
          }

        }
      },
      (dismiss) => { }
    );
  }
  copyQuote(sourceQuote) {
    this.spinner.show();
    const newName = `Copy of ${sourceQuote?.Name}`;
    const subscription$ = this.cartService
      .copyObjectById(CpqObjects.Quote, sourceQuote?.Id, newName).pipe(
        switchMap(x => {
          const Id = x.OpportunityId
          return this.cartService
            .getCpqObjects(CpqObjects.Opportunities, Id)
        })
      ).subscribe(
        (res: Opportunity) => {
          this.spinner.hide();
          if (res && res?.Quotes.length) {
            console.log(res?.Quotes);
            this.quoteDetail = res?.Quotes;
            this.setQuoteDetail();
          }
        },
        err => {
          this.spinner.hide();
          this.toastr.error(
            'There was an error while copying revisions', 'Error', {
            disableTimeOut: true,
            closeButton: true
          })
        }
      );
    this.commonSubscriptions.add(subscription$)
  }
  //OpportunityId
  delete(record) {
    this.dialogRef = undefined;
    if (record.IsPrimary) {
      this.dialogRef = this.dialog.open(this.InformationDialog, {
        width: "400px",
      });
    } else {
      this.dialogRef = this.dialog.open(this.ConfirmDialog, {
        width: "400px",
      });
      const index = this.quoteDetail.findIndex((item) => item.Id === record.Id);
      const subscription$ = this.dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.spinner.show();
          const subscription = this.cartService
            .deleteObjectByObjectId(CpqObjects.Quote, record.Id)
            .subscribe({
              next: (isDeleted: any) => {
                if (isDeleted) {
                  this.quoteDetail = this.quoteDetail.filter(
                    (item) => item.Id !== record.Id
                  );
                  this.dataSource = new MatTableDataSource<any>(
                    JSON.parse(JSON.stringify(this.quoteDetail))
                  );
                }
                this.spinner.hide();
              },
              error: (err) => {
                this.toastr.error(
                  "There is fatal error while fetching revision",
                  "Error",
                  {
                    disableTimeOut: true,
                    closeButton: true,
                  }
                );
              },
            });
          this.commonSubscriptions.add(subscription);
        }
        this.commonSubscriptions.add(subscription$);
      });
    }
    console.log("Delete record", record);
  }

  cancel() {
    this.dialogRef.close();
  }

  download(record) {
    const date = this.datePipe.transform(new Date(), 'MM-dd-yyyy');
    const name = this.opportunityName + '-' + record.BudgetaryNumber + '-' + date
    this.docGenService.openModal(record.Id, name);
    //  this.productService.quoteProposalUrl(record.Id);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.quote?.currentValue) {
      console.log("Quote", changes?.quote?.currentValue);
      this.quoteDetail = changes?.quote?.currentValue;
      this.setQuoteDetail();
    }
  }

  setQuoteDetail() {
    const index = this.quoteDetail.findIndex(
      (item) => item?.IsPrimary === 1 || item?.IsPrimary === true
    );
    if (index !== -1) {
      this.quoteDetail[index]["Selected"] = true;
      this.loadCartDetails.emit(this.quoteDetail[index]);
    }
    this.colDefinition = this.columnDefinitionFactory();
    this.dataSource = new MatTableDataSource<any>(this.quoteDetail);
    this.cartService.enableSubmitQuote.next(true);
  }

  onRadioSelection(event) {
    if (event.field === "selected") {
      const data = this.quoteDetail.find(
        (record) => record.Id === event.item.Id
      );
      this.loadCartDetails.emit(data);
    }
  }

  ngOnDestroy(): void {
    this.commonSubscriptions.unsubscribe();
  }
  onActionClick(event: { eventName: string, eventData: any }) {
    switch (event.eventName) {
      case 'copy':
        this.copyQuote(event.eventData);
        break;
      case 'edit':
        this.edit(event.eventData);
        break;
      case 'delete':
        this.delete(event.eventData);
        break;
      case 'download':
        this.download(event.eventData);
        break;
    }
  }
}
