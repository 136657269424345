<div fxLayout="row" fxLayoutAlign="space-between center" class="cpq-breadcrumb">
  <ng-content></ng-content>
  <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between center" *ngIf="!isSalesForceFlow">
    <div *ngIf="!isGuest">
      <i class="fa fa-envelope-o" aria-hidden="true"></i><span class="cpq-contact-links">&nbsp;<a
          (click)="openHubspotSupport()">Contact Support</a></span>
    </div>
    <div>
      <i class="fa fa-envelope-o" aria-hidden="true"></i><span class="cpq-contact-links">&nbsp;<a
          (click)="openHubspotSales()">Contact Sales</a></span>
    </div>
  </div>
</div>