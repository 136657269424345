import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  MatDialog,
  MatDialogConfig,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import { GuestConfigService } from "@cpq-app/adminstration/guest-config/guest-config.service";
import {
  CartService,
  CpqObjects,
  CpqProposal,
  CpqQuote,
  CpqQuoteline,
} from "@cpq-app/services/cart.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { forkJoin, from, Observable, of, Subject, Subscription } from "rxjs";
import { catchError, concatMap, map, mergeMap, switchMap, takeLast, takeUntil } from "rxjs/operators";
import {
  HubspotDialogComponent,
  HubspotForms,
} from "../hubspot-modal/hubspot-modal.component";
import { SubmitQuoteModelComponent } from "../submit-quote-model/submit-quote-model.component";
import { ThankYouModalComponent } from "../thank-you-modal/thank-you-modal.component";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { DatePipe } from "@angular/common";
import { DocGenService } from "../doc-gen-modal/doc-gen.service";
import { ProductService } from "@cpq-app/services/product.service";
import { Quote } from "@cpq-app/models/twg-interfaces";

pdfMake.vfs = pdfFonts;
const FPX_QUANTITY_MAX_LIMIT = 99999;

@Component({
  selector: "app-cart",
  templateUrl: "./cart.component.html",
  styleUrls: ["./cart.component.scss"],
  providers: [DatePipe],
})
export class CartComponent implements OnInit, OnChanges, OnDestroy {
  @Input() quoteId: string;
  @Input() opportunityName = ''
  @Input() opportunityId: string;
  @Output() startNewConfiguration = new EventEmitter();
  @Output() loadFreshOpportunity = new EventEmitter();
  @Input() quoteSubmittedStatus: boolean;
  @Input() isSalesForceFlow: boolean;
  @Input() isPrimaryQuote: boolean;
  quote;
  quoteCartCreationDate: Date;
  cartProductCount: number;
  fetchSubscription = new Subscription();
  commonSubscriptions = new Subscription();
  updateSubscription: Subscription;
  products: any[] = [];
  isGuest = true;

  proposalResult: any;
  tableDesigCriteria: any;
  tableRackData: any;
  headerImageLeft: any;
  headerImageRight: any;
  billingToAddress: any;
  organizationAddress: any;
  shippedToAddress: any;
  enableSubmitButton = true;
  internalUser;
  readonly HIDDEN_PRICE = "••••••••••";
  readonly MAX_NUMBER_CONCURRENT_CONFIG_SESSIONS = 4;
  subscription$: Subscription[] = [];
  private readonly destroy$ = new Subject<void>();
  isQuoteRequiresUpdate = false;

  readonly displayedColumns = [
    "graphicName__c",
    "Name",
    "ExtendedDescription",
    "Quantity",
    "Actual_Extended_List_Price__c",
    "Model_Code__c",
  ];

  readonly TOASTR = {
    TEXT: {
      WARNING_TITLE: `Uh oh...`,
      ERROR_TITLE: `We're sorry...`,
      MSG: {
        OPEN_CONFIG_ERROR: `Something went wrong while opening your product; please try again.`,
        UPDATE_ERROR: `Something went wrong while updating your quote; please try again.`,
        INVALID_ENTRY: `That value was invalid; please try again.`,
      },
    },
    VALUE: {
      WARNING_FADE_TIMEOUT: 5000, // in mS
      ERROR_FADE_TIMEOUT: 5000, // in mS
    },
  };

  constructor(
    private cartService: CartService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private guestService: GuestConfigService,
    private router: Router,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private docGenService: DocGenService,
    private productService: ProductService
  ) { }

  ngOnInit() {
    this.internalUser = this.cartService.internalUser;
    this.isGuest = this.guestService.getGuestUserDetails()?.isGuest || false;
    this.cartService.enableSubmitQuote.subscribe((enable: boolean) => {
      this.enableSubmitButton = enable;
    })
    this.cartService.updateCart.pipe(takeUntil(this.destroy$)).subscribe(((resp) => {
      if (this.quoteId) {
        this.fetchCart(this.quoteId);
      } else {
        this.clearCart();
      }
    }))
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.quoteId) {
      this.fetchSubscription.unsubscribe();
      if (this.quoteId) {
        this.fetchCart(this.quoteId);
      } else {
        this.clearCart();
      }
    } else {
      console.log("Changes fired but not for quoteId");
    }
  }

  trackByQuoteLineId(index: number, quoteLine: CpqQuoteline) {
    return quoteLine.ProductId;
  }

  ngOnDestroy() {
    this.fetchSubscription.unsubscribe();
    this.subscription$?.forEach(sub => sub?.unsubscribe());
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }

  fetchCart(quoteId: string) {
    // Erase the cart values and fetch new ones
    this.spinner.show();
    this.clearCart();
    let deleteEmptyQuoteLine = [];
    this.subscription$.push(
      this.cartService.getCpqObjects(CpqObjects.Quote, this.quoteId).pipe(
        switchMap((quote: any) => {
          const filteredQuoteLines = quote?.Lines.filter(item => item?.SelectedProduct && item.SelectedProduct.ProductId !== null);
          deleteEmptyQuoteLine = quote?.Lines.filter(item => item?.ProductId === null);
          this.quote = quote;
          this.quote.Lines = filteredQuoteLines;
          this.isPrimaryQuote = quote.IsPrimary;
          this.recalculateQuoteTotal();
          this.spinner.hide();
          return of(quote); // Assuming 'of' is imported from 'rxjs'
        })
      ).subscribe({
        next: (quote: any) => {
          if (deleteEmptyQuoteLine?.length > 0) {
            deleteEmptyQuoteLine.forEach(ql => {
              this.deleteProduct(ql?.Id);
            });
          }
        },
        complete: () => {
          this.isQuoteRequiresUpdate = this.checkIfQuoteRequiresUpdate();
          this.cartService.reConfiguration.next(this.isQuoteRequiresUpdate);
        },
        error: (err) => {
          this.spinner.hide();
          this.toastr.error(
            'Error while fetching Cart Product', 'Error', {
            disableTimeOut: true,
            closeButton: true
          });
        }
      })
    );
  }

  checkIfQuoteRequiresUpdate() {
    let needRequiresUpdate = false;
    if (this.quote.Lines) {
      return needRequiresUpdate = this.quote.Lines.some(item => item.RequiresUpdate__c || (!item?.ProductNumber || item?.ProductNumber === '' || item?.ProductNumber === null)
      );
    }
  }


  refreshProductsPricing() {
    const currentDate = new Date();
    const expirationDate = new Date(this.quote.ExpirationDate);
    if (expirationDate <= currentDate && this.quote.quoteLines.length > 0) {
      this.toastr.info(
        "The pricing on your quote has expired and will now be updated",
        "Information",
        {
          closeButton: true,
          positionClass: "toast-top-center",
          timeOut: 15000,
        }
      );

      if (this.updateSubscription) {
        this.updateSubscription.unsubscribe();
      }

      // Alter the local copy of the expiration date to prevent an unecessary repeat
      currentDate.setDate(currentDate.getDate() + 1);
      this.quote.ExpirationDate = currentDate;

      this.updateSubscription = from(this.quote.quoteLines)
        .pipe(
          mergeMap<CpqQuoteline, Observable<boolean>>(
            (quoteline) => this.cartService.resaveProduct(quoteline.ProductId),
            this.MAX_NUMBER_CONCURRENT_CONFIG_SESSIONS
          ),
          takeLast(1),
          mergeMap((x) => this.cartService.updateQuoteData(this.quoteId))
        )
        .subscribe
        // Do nothing on success
        ();
    }
  }

  private clearCart() {
    if (this.quote?.Lines?.length && !this.isSalesForceFlow) {
      this.quote.Lines = [];
    }
  }

  public calculateNetTotal() {
    return this.quote?.Lines?.reduce(
      (price, quoteLine) => price + quoteLine?.TotalSellingPrice,
      0
    );
  }

  quoteProposalUrl(quoteId: string) {
    this.productService.quoteProposalUrl(quoteId);
  }

  navigateToPreSelects() {
    this.startNewConfiguration.emit();
  }

  deleteProduct(productId: string) {
    this.spinner.show();
    this.cartService.deleteObjectByObjectId(CpqObjects.QuoteLine, productId).subscribe(res => {
      this.fetchCart(this.quoteId);
      this.recalculateQuoteTotal();
      // this.spinner.hide();
    }, err => {
      console.log(err);
      this.spinner.hide();
    })
  }

  recalculateQuoteTotal() {
    if (this.quote?.TotalAmount !== undefined) {
      const sum = (total, ql) => total + (ql.TotalSellingPrice || 0);
      this.quote.TotalAmount = this.quote?.Lines.reduce(sum, 0);
    }
  }

  openSubmitQuoteDialog() {
    const submitQuoteDialogConfig = this.submitQuoteDialogConfig();
    const submitQuoteDialogRef = this.dialog.open(
      SubmitQuoteModelComponent,
      submitQuoteDialogConfig
    );

    const thankYouConfig = this.thankYouModalConfig();
    submitQuoteDialogRef.afterClosed().pipe(
      switchMap(x => {
        if (x.success) {
          return this.dialog.open(ThankYouModalComponent, thankYouConfig).afterClosed();
        }
        return of(x);
      })
    ).subscribe(x => {
      if (!this.isGuest && x.success) {
        this.clearCart();
        this.loadFreshOpportunity.emit(x.success)
      } else if (x.isAccountAssociationChange && !x.success) {
        this.loadFreshOpportunity.emit(x.success)
      }
    }, err => {

    });

  }

  private submitQuoteDialogConfig() {
    const submitQuoteDialogConfig = new MatDialogConfig();
    submitQuoteDialogConfig.disableClose = true;
    submitQuoteDialogConfig.autoFocus = true;
    submitQuoteDialogConfig.width = "600px";
    submitQuoteDialogConfig.data = {
      quoteName: this.quote?.Name,
      quoteNote: this.quote?.Note,
      quoteId: this.quoteId,
      opportunityId: this.opportunityId,
      quoteNumber: this.quote.BudgetaryNumber,
      fileName: this.opportunityName + '-' + this.quote.BudgetaryNumber + '-' + this.datePipe.transform(new Date(), 'MM-dd-yyyy')
    };
    return submitQuoteDialogConfig;
  }

  private thankYouModalConfig() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "600px";
    dialogConfig.data = {};
    return dialogConfig;
  }

  updateQuantity(quoteline: CpqQuoteline) {
    const qty = quoteline.Quantity;
    const minQty = quoteline?.SelectedProduct?.MinQuantity || 0;
    if (Number.isNaN(qty)) {
      this.cartService.reConfiguration.next(true);
      this.toastr.error(this.TOASTR.TEXT.MSG.INVALID_ENTRY, this.TOASTR.TEXT.ERROR_TITLE, {
        timeOut: this.TOASTR.VALUE.ERROR_FADE_TIMEOUT,
      });
      return;
    }
    if (this.isQuantityOutOfRange(qty, minQty)) {
      this.cartService.reConfiguration.next(true);
      return;
    }
    // if (qty === 0) {
    //   this.deleteProduct(quoteline.Id);
    // } else {
      if (quoteline.TotalSellingPrice != undefined) {
        quoteline.TotalSellingPrice =
          quoteline.UnitSellingPrice * quoteline.Quantity;
        this.recalculateQuoteTotal();
    }
      this.spinner.show();
      this.cartService.updateObjectById(CpqObjects.QuoteLine, quoteline?.Id, {
        Quantity: qty,
        UnitSellingPrice: quoteline.TotalSellingPrice
      }).subscribe({
        next: () => {
          this.spinner.hide();
        },
        complete: () => {
          this.spinner.hide();
          this.toastr.success('Quantity Updated successfully');
        },
        error: (err) => {
          this.spinner.hide();
          this.toastr.error(
            'There was an error while updating Qty', 'Error', {
            disableTimeOut: true,
            closeButton: true
          }
          );
        }
      });
   // }
  }

  isSubmitDisabled(): boolean {
    const allValid = this.quote?.Lines?.reduce(
      (valid: boolean, ql) => valid && this.isQuoteLineValid(ql),
      true
    );
    if (this.internalUser) {
      return !this.enableSubmitButton ? !this.enableSubmitButton : !allValid
    }
    return !allValid;
  }

  isQuoteLineValid(ql: CpqQuoteline): boolean {
    // if Quantity is 0 then it returns false
    return ql.Quantity && !this.isQuantityOutOfRange(ql.Quantity, ql?.SelectedProduct?.MinQuantity ?? 0);
  }

  getProductImage(productId: string) {
    const selectedProduct = this.products.filter(
      (item) => item.Id === productId
    );
    return selectedProduct[0].graphicName__c;
  }
  getProductModelCode(productId: string) {
    const selectedProduct = this.products.filter(
      (item) => item.Id === productId
    );
    return selectedProduct[0].Model_Code__c;
  }
  getProductProposalNotes(productId: string) {
    const selectedProduct = this.products.filter(
      (item) => item.Id === productId
    );
    return selectedProduct[0].Proposal_Notes__c !== undefined
      ? selectedProduct[0].Proposal_Notes__c
      : "";
  }
  getProductActualExtendedListPrice(productId: string) {
    const selectedProduct = this.products.filter(
      (item) => item.Id === productId
    );
    return selectedProduct[0].Actual_Extended_List_Price__c;
  }

  getUnitPrice(ProductId: string) {
    const selectedProduct = this.products.filter(
      (item) => item.Id === ProductId
    );
    return selectedProduct[0]?.Actual_Unit_List_Price__c;
  }

  getListPrice(ProductId: string) {
    const selectedProduct = this.products.filter(
      (item) => item.Id === ProductId
    );
    return selectedProduct[0]?.TotalList;
  }
  /**
   * Opens a material modal to show Hubspot forms
   */
  openHubspotRequest() {
    this.dialog.open(HubspotDialogComponent, {
      data: {
        formName: HubspotForms.RequestAccess,
      },
    });
  }

  onModelCodeClick(quoteLineId: string) {
    // this.spinner.show();
    // const subscription$ = this.cartService
    //   .reopenConfiguration(productId)
    //   .subscribe(configData => {
    //     this.router.navigate(
    //       [
    //         '/products/configuration',
    //         this.opportunityId,
    //         this.quoteId,
    //         configData.configId
    //       ],
    //       { queryParams: { update: 'product', productID: productId } }
    //     );
    //   }, err => {
    //     // Navigation event failed
    //     this.toastr.warning(this.TOASTR.TEXT.MSG.OPEN_CONFIG_ERROR, this.TOASTR.TEXT.WARNING_TITLE, {
    //       timeOut: this.TOASTR.VALUE.WARNING_FADE_TIMEOUT,
    //     });
    //     this.cartService.updateQuoteData(this.quoteId);
    //     this.spinner.hide();
    //   });
    this.router.navigate([
      '/products/configuration',
      this.opportunityId,
      this.quoteId,
      quoteLineId
    ]);
  }

  isQuantityOutOfRange(quantity: number, minQty: number): boolean {
    if (quantity === null || quantity <= 0 || quantity > FPX_QUANTITY_MAX_LIMIT || quantity < minQty) {
      return true;
    } else {
      return false
    }
  }

  change(event) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value.replace(/[^0-9]/g, '').replace(/^0+/, '');
    inputElement.value = value;
    const allValid = this.quote?.Lines?.reduce(
      (valid: boolean, ql) => valid && this.isQuoteLineValid(ql),
      true
    );
    this.cartService.reConfiguration.next(!allValid || this.isQuoteRequiresUpdate);
  }

  documentGenerationModel(quoteId: string) {
    this.docGenService.openModal(quoteId);
  }

}
