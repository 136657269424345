import { Component, OnInit } from '@angular/core';
import { PermissionsService } from '@cpq-app/shared/services/permissions.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from '@cpq-app/shared/services/alert.service';
import { PersonaSetupService } from '@cpq-app/setup/rbac-setup/persona/persona.service';
import { AppConstant } from '@cpq-app/shared/constants/app-constants';
import { forkJoin } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormArray, EmailValidator } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { OrganizationService } from '@cpq-app/adminstration/organization/organization.service';
import { GuestConfigService } from '@cpq-app/adminstration/guest-config/guest-config.service';
import { country } from '../country';
import { state } from '../state';
import { cities } from '../cities';
import { MSALService } from '@cpq-app/shared/services/msal.service';
import { UsersService } from '@cpq-app/adminstration/users/users.service';
import { CartService, CpqObjects } from '@cpq-app/services/cart.service';
import { HeaderService } from '@cpq-app/header/header.service';
import { HeaderComponent } from 'dist/launchpad-iot-dynamic-forms/lib/FormElements/Header.component';
import { addressPattern, cityNamePattern, emailPattern, firstnamePattern, namePattern, titlePattern, zipcodePattern } from '@cpq-app/regex';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  public countries;
  public states;
  public cities;
  statesList;
  citiesList;
  personaList = [];
  organizationList = [];
  organization;
  fpxPartnerId;
  userDetail: UntypedFormGroup;
  userData;
  public countryAttrValue = '';
  public stateAttrValue = '';
  public cityAttrValue = '';

  constructor(private permissionSvc: PermissionsService,
    private alertService: AlertService,
    private personaService: PersonaSetupService,
    private organizationService: OrganizationService,
    private fb: UntypedFormBuilder,
    private guestConfigService: GuestConfigService,
    private toastr: ToastrService,
    private msalService: MSALService,
    private userService: UsersService,
    private spinner: NgxSpinnerService,
    private orgService: OrganizationService,
    private cartService: CartService,
    private headerService: HeaderService
  ) { }

  ngOnInit(): void {
    this.countries = country;
    this.states = state;
    this.cities = cities;
    this.getUserProfile();
    this.createProfileForm();
  }

  getUserProfile() {
    this.spinner.show();
    const loggedInUserAdInfo = this.msalService.getUser().idToken;
    const Id = sessionStorage.getItem('userId');
    this.userService.getUserById(Id).subscribe((res) => {
      this.spinner.hide();
      if (!res['Id']) {
        this.alertService.error(
          'User not found'
        );
      } else {
        this.userData = res;
        if (
          this.userData === null ||
          typeof this.userData === typeof undefined
        ) {
          console.log('user is super admin');
        } else {
          this.userData = res;
          this.updateProfileForm();
        }
      }
    });
  }

  createProfileForm() {
    this.userDetail = this.fb.group({
      Title: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.pattern(titlePattern),
        ]),
      ],

      FirstName: [
        this.userData?.FirstName,
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.pattern(firstnamePattern),
        ]),
      ],
      LastName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.pattern(namePattern),
        ]),
      ],
      UserName: [
        {
          value: '',
          disabled: true
        },
        Validators.compose([
          Validators.required,
          Validators.pattern(emailPattern),
        ]),
      ],
      Email: [
        {
          value: '',
          disabled: true,
        },
        Validators.compose([
          Validators.required,
          Validators.pattern(emailPattern),
        ]),
      ],
      address1: [
        '',
        Validators.compose([
          Validators.minLength(2),
          Validators.pattern(addressPattern),
        ]),
      ],
      address2: [
        '',
        Validators.pattern(addressPattern),
      ],
      country: [''],
      state: [''],
      city: [
        '',
        Validators.compose([
          Validators.minLength(2),
          Validators.maxLength(30),
          Validators.pattern(cityNamePattern),
        ])
      ],
      zipcode: [
        '',
        Validators.compose([
          Validators.pattern(zipcodePattern),
        ]),
      ]
    });

  }

  updateProfileForm() {
    this.userDetail = this.fb.group({
      Title: [
        this.userData?.Title,
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.pattern(titlePattern),
        ]),
      ],

      FirstName: [
        this.userData?.FirstName,
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.pattern(firstnamePattern),
        ]),
      ],
      LastName: [
        this.userData?.LastName,
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.pattern(namePattern),
        ]),
      ],
      UserName: [
        {
          value: this.userData.UserName,
          disabled: true,
        },
        Validators.compose([
          Validators.required,
          Validators.pattern(emailPattern),
        ]),
      ],
      Email: [
        {
          value: this.userData?.Email,
          disabled: true,
        },
        Validators.compose([
          Validators.required,
          Validators.pattern(emailPattern),
        ]),
      ],
      address1: [
        this.userData?.Address?.Street?.split(',')[0],
        Validators.compose([
          Validators.minLength(2),
          Validators.pattern(addressPattern),
        ]),
      ],
      address2: [
        this.userData?.Address?.Street?.split(',')[1],
        Validators.pattern(addressPattern),
      ],
      country: [''],
      state: [''],
      city: [
        this.userData?.Address?.City,
        Validators.compose([
          Validators.minLength(2),
          Validators.maxLength(30),
          Validators.pattern(cityNamePattern),
        ])
      ],
      zipcode: [
        this.userData?.Address?.PostalCode,
        Validators.compose([
          Validators.pattern(zipcodePattern),
        ]),
      ]
    });
    const indexCountry = this.countries.findIndex(
      (x) => x.name === this.userData?.Address?.Country
    );
    const indexState = this.states.findIndex(
      (x) => x.name === this.userData?.Address?.State
    );
    console.log(this.countries[indexCountry])
    this.userDetail.controls['country'].setValue(this.countries[indexCountry], {
      onlySelf: true,
    });
    
    this.onCountrySelect(this.countries[indexCountry]);
    this.userDetail.controls['state'].setValue(this.states[indexState], {
      onlySelf: true,
    });
    this.onStateSelect(this.states[indexState]);
    this.userDetail.controls['city'].setValue(this.userData?.Address?.City);
  }

  onCountrySelect(country) {
    this.statesList = [];
    this.citiesList = [];
    this.userDetail.get('state').patchValue('');
    this.userDetail.get('city').patchValue('');
    this.statesList = this.states.filter(function (s) {
      return parseInt(s.country_id) === parseInt(country?.id);
    });
  }
  onStateSelect(state) {
    this.citiesList = [];
    this.userDetail.get('city').patchValue('');
    this.citiesList = this.cities.filter(function (i) {
      return parseInt(i.state_id) === parseInt(state?.id);
    });
  }

  onCitySelect(city) { }


  updateUser() {
    const formdata = this.userDetail.getRawValue();
    console.log(formdata,'ddff');
    const obj = this.userData;
    obj['Title'] = formdata.Title;
    obj['FirstName'] = formdata.FirstName;
    obj['LastName'] = formdata.LastName;
    obj['Username'] = formdata.UserName;
    obj['Email'] = formdata.Email;
    obj['Street'] = formdata.address1 +','+ formdata.address2;
    obj['PostalCode'] = formdata.zipcode;
    obj['City'] = formdata?.city;
    obj['Email'] = formdata.email;
    obj['Country'] = formdata?.country?.name;
    obj['State'] = formdata?.state?.name;
    this.editUserFunction(obj, sessionStorage.getItem('userId'));
  }

  editUserFunction(obj, id) {
      const userObj = {};
      let Street = "";
      userObj['FirstName'] = obj.FirstName;
      userObj['LastName'] = obj.LastName;
      userObj['Username'] = obj.UserName;
      userObj['Email'] = obj.Email;
      userObj['Title'] = obj.Title;
      userObj['Address'] = {};
      userObj['Country'] = obj['Country'];
      userObj['City'] = obj.City;
      userObj['PostalCode'] = obj.PostalCode;
      userObj['State'] = obj.State;
      userObj['Street'] = obj.Street;
      userObj['IsActive'] = true;
      this.spinner.show();
      this.cartService
        .updateObjectById(CpqObjects.User, sessionStorage.getItem('userId'), userObj).subscribe((res)=>{
          this.spinner.hide();
          if (res.Id) {
            this.spinner.hide();
            this.toastr.info('User profile updated successfully.')
            let userName = res['FirstName'] + ' ' + res['LastName'];
            this.headerService.setUsername(userName);
          } else {
            this.spinner.hide();
            this.toastr.error('User profile updation failed.')
          }
          }
        )
}
}
