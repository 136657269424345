import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { GuestConfigService } from '@cpq-app/adminstration/guest-config/guest-config.service';
import { CartService, CpqQuote, CpqProposal } from '@cpq-app/services/cart.service';
import { getTime } from 'ngx-bootstrap/chronos/utils/date-getters';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { HubspotDialogComponent, HubspotForms } from '../hubspot-modal/hubspot-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ProductService } from '@cpq-app/services/product.service';
import { DocGenService } from '../doc-gen-modal/doc-gen.service';

@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss'],
  providers: [DatePipe]
})
export class QuoteComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() quoteId: string;
  quotes = new MatTableDataSource<any>();
  isQuotesLoading = true; // to do change to true
  quoteRange = '1';
  noQuotesItems = false;
  quoteSubscription: Subscription;
  isGuest: boolean;
  LOGIN_SPINNER = 'login';

  @Output() startNewConfiguration = new EventEmitter();

  readonly HIDDEN_PRICE = '••••••••••';
  private paginator: MatPaginator;
  private sort: MatSort;
  minDate: Date;
  maxDate: Date;
  minDateInitial: Date;
  dateSelectionError = false;
  dateSelectionErrorText: string;
  startDate: any;
  endDate: any;
  constructor(
    private datePipe: DatePipe,
    private cartService: CartService,
    public dialog: MatDialog,
    private guestService: GuestConfigService,
    private spinner: NgxSpinnerService,
    private productService: ProductService,
    private docGenService: DocGenService
  ) {
    this.minDate = new Date();
    this.maxDate = new Date();
    this.minDateInitial = new Date();
    this.minDate.setMonth(this.minDate.getMonth() - 6);
    this.minDateInitial.setMonth(this.maxDate.getMonth() - 1)
    this.daterangepickerModel = [this.minDateInitial, this.maxDate];
  }
  daterangepickerModel: any[]
  displayedColumnsForQuote: string[] = [
    'ModifiedTime',
    'BudgetaryNumber',
    'Name',
    'Note',
    'TotalAmount',
    'ExpirationDate'
  ];

  @ViewChild(MatSort) set matSort(mSort: MatSort) {
    this.sort = mSort;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mPaginator: MatPaginator) {
    this.paginator = mPaginator;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.quotes.paginator = this.paginator;
    this.quotes.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.quotes.filter = filterValue;
  }

  ngAfterViewInit() {
    this.setDataSourceAttributes();
  }

  ngOnInit() {
    this.isGuest = this.guestService.getGuestUserDetails().isGuest || false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.quoteId) {
      this.fetchQuotesWithinRange(this.startDate, this.endDate); // to do
    }
  }

  fetchQuotesWithinRange(startDate, endDate) {
    this.isQuotesLoading = true;
    if (this.quoteSubscription) {
      this.quoteSubscription.unsubscribe();
    }
    this.quotes.data = [];
    if (startDate && endDate) {
      this.spinner.show();
      this.quoteSubscription = this.cartService
        .fetchQuotes(startDate, endDate)
        .pipe(
        // map<CpqQuote[], CpqQuote[]>(qs => qs.filter(q => CpqQuote.checkIsClosed(q) && q.Selling_Price__c))
      )
        .subscribe(
          quotes => {
            this.spinner.hide();
            this.quotes.data = this.quotes.data.concat(quotes);
            this.noQuotesItems = this.quotes.data.length === 0;
          },
          err => {
            this.spinner.hide();
            this.spinner.hide(this.LOGIN_SPINNER);
            this.isQuotesLoading = false;
            this.ngAfterViewInit();
          },
          () => {
            this.isQuotesLoading = false;
            this.ngAfterViewInit();
          }
        );
    } else {
      this.spinner.hide(this.LOGIN_SPINNER);
    }

  }
  dateValidation(selectedDate) {
    let validationObject = { isDateValid: false, validationMessage: '' }
    if (selectedDate == null) {
      validationObject.isDateValid = true
      validationObject.validationMessage = "Please select valid dates"
    } else if (selectedDate == '' || (selectedDate.length < 2) || (selectedDate[0] == null || selectedDate[0] == '') || (selectedDate[1] == null || selectedDate[1] == '')) {
      validationObject.isDateValid = true
      validationObject.validationMessage = "Please select valid dates"
    } else if ((new Date(this.dateFormat(selectedDate[0])).getTime() > new Date(this.dateFormat(selectedDate[1])).getTime())) {
      validationObject.isDateValid = true
      validationObject.validationMessage = "Please check from-date and to-Date selection"
    } else if (new Date(this.dateFormat(selectedDate[1])).getTime() > (new Date(this.dateFormat(this.maxDate)).getTime())) {
      validationObject.isDateValid = true
      validationObject.validationMessage = "You can't get future Quotes"
    } else if (new Date(this.dateFormat(selectedDate[0])).getTime() < (new Date(this.dateFormat(this.minDate)).getTime())) {
      validationObject.isDateValid = true
      validationObject.validationMessage = "You can get only past 6 months Quotes"
    }
    return validationObject;
  }
  dateFormat(date) {
    return this.datePipe.transform(new Date(date), 'yyyy-MM-dd');
  }
  onQuoteRangeChange(item) {
    let dateValidationObject = this.dateValidation(item)
    if (dateValidationObject.isDateValid) {
      this.dateSelectionError = dateValidationObject.isDateValid;
      this.dateSelectionErrorText = dateValidationObject.validationMessage;

    } else {
      this.dateSelectionError = false;
      // this.startDate = this.dateFormat(item[0]);
      // this.endDate = this.dateFormat(item[1]);
      this.startDate = new Date(item[0].setDate(item[0].getDate()));
      this.endDate = new Date(item[1].setDate(item[1].getDate()));
      this.fetchQuotesWithinRange(this.startDate, this.endDate); //to do
    }

  }

  quoteProposalUrl(quoteId: string) {
    // return this.cartService.getProposalUrl(proposal?.Id);
    // this.productService.quoteProposalUrl(quoteId);
    this.docGenService.openModal(quoteId);
  }

  /**
   * Opens a material modal to show Hubspot forms
   */
  openHubspotRequest() {
    this.dialog.open(HubspotDialogComponent, {
      data: {
        formName: HubspotForms.RequestAccess
      }
    });
  }

  navigateToPreSelects() {
    this.startNewConfiguration.emit();
  }
}
