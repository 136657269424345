import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { DynamicForm } from 'src/app/shared/models/dynamic-form';

@Injectable({
  providedIn: 'root',
})
export class PersonaSetupService {
  constructor(private http: HttpClient) { }

  private backendUrl = environment.B2CConfigs.BackendURL;

  //#region global variables
  personaData;
  activeRoles;
  associatedRoles;
  organizationTypes: { optionName: string; value: string | object }[];
  breadcrumbText = '';
  selectedOrgTypes;
  //#endregion

  getPersonas() {
    return this.http.get(`${this.backendUrl}/authorization/persona/all`);
  }

  createPersona(persona) {
    return this.http.post(`${this.backendUrl}/authorization/persona`, persona);
  }

  updatePersona(persona, personaId) {
    return this.http.put(
      `${this.backendUrl}/authorization/persona/${personaId}`,
      persona
    );
  }

  getRoles() {
    return this.http.get(`${this.backendUrl}/authorization/role/all`);
  }

  getActiveRoles() {
    return this.http.get(`${this.backendUrl}/authorization/role/active`);
  }

  deletePersona(personaId) {
    return this.http.delete(
      `${this.backendUrl}/authorization/persona/${personaId}`
    );
  }

  getOrganizationTypes() {
    return this.http.get(`${this.backendUrl}/organizations/types`);
  }

  getPermissionSummary(personaId) {
    return this.http.get(
      `${this.backendUrl}/authorization/persona/summary/${personaId}`
    );
  }

  getPersonasByOrganizationType(organizationTypeId) {
    return this.http.get(
      `${this.backendUrl}/authorization/persona/orgtype/${organizationTypeId}`
    );
  }

  getAssociatedRoles(personaId) {
    return this.http.get(
      `${this.backendUrl}/authorization/role/getAssociatedRoles/${personaId}`
    );
  }

  getCreatePersonaFormData() {
    const formData: Array<DynamicForm> = [
      {
        tabName: 'Details',
        tabElements: [
          {
            classNames: ['col-sm-4', 'col-sm-4'],
            controlNames: ['PersonaName', 'Alias'],
            controlTypes: ['floatingText', 'floatingText'],
            acceptedTypes: ['text', 'text'],
            controlFlags: [false, false],
            currentValues: ['', ''],
            disabled: [false, false],
            formArray: undefined,
            labels: ['Persona Name', 'Alias'],
            options: undefined,
            parentClassName: 'row',
            placeholder: ['', ''],
            readonly: [false, false],
            tagName: undefined,
            validators: [
              {
                email: false,
                maxLength: 30,
                minLength: 2,
                pattern: '^(?=[a-zA-Zs].*)[a-zA-Z0-9_ d!-]+$',
                max: undefined,
                min: undefined,
                patternMessage:
                  'Persona Name will accept only (space,_,-) and it should not start with numbers and special characters.',
                required: true,
                exists: undefined,
                existsMessage: undefined,
              },
              {
                email: false,
                maxLength: 10,
                minLength: 2,
                pattern: '^(?=[a-zA-Zs].*)[a-zA-Z0-9_d!-]+$',
                max: undefined,
                min: undefined,
                patternMessage:
                  'Alias will accept only (_,-) and it should not start with numbers and special characters.',
                required: true,
                exists: undefined,
                existsMessage: undefined,
              },
            ],
            cssStyles: [{}],
            multiSelectList: undefined,
            dependentFormControls: undefined,
          },
          {
            classNames: ['col-sm-4'],
            controlNames: ['Identifier'],
            controlTypes: ['floatingText'],
            acceptedTypes: ['text'],
            controlFlags: [false],
            currentValues: [''],
            disabled: [false],
            formArray: undefined,
            labels: ['Identifier'],
            options: [undefined],
            parentClassName: 'row',
            placeholder: [''],
            readonly: [false],
            tagName: [undefined],
            validators: [
              {
                email: false,
                patternMessage:
                  'Identifier will accept only (_,-) and it should not start with numbers and special characters.',
                required: true,
                pattern: '^(?=[a-zA-Zs].*)[a-zA-Z0-9_d!-]+$',
                max: undefined,
                min: undefined,
                minLength: 2,
                maxLength: 35,
                exists: undefined,
                existsMessage: undefined,
              },
            ],
            cssStyles: [{}],
            multiSelectList: undefined,
            dependentFormControls: undefined,
          },
          {
            classNames: ['col-sm-4'],
            controlNames: ['Description'],
            controlTypes: ['textArea'],
            acceptedTypes: [undefined],
            controlFlags: [false],
            currentValues: [''],
            disabled: [false],
            formArray: undefined,
            labels: ['Description'],
            options: [undefined],
            parentClassName: 'row',
            placeholder: [''],
            readonly: [false],
            tagName: [undefined],
            validators: [
              {
                email: false,
                required: true,
                pattern: undefined,
                patternMessage: undefined,
                max: undefined,
                min: undefined,
                minLength: 2,
                maxLength: 200,
                exists: undefined,
                existsMessage: undefined,
              },
            ],
            cssStyles: [{}],
            multiSelectList: undefined,
            dependentFormControls: undefined,
          },
          {
            controlTypes: ['toggleButton'],
            acceptedTypes: [undefined],
            controlFlags: [false],
            labels: [''],
            currentValues: [true],
            controlNames: ['IsActive'],
            classNames: ['col-sm-6'],
            parentClassName: 'row',
            options: [undefined],
            disabled: [undefined],
            readonly: [undefined],
            tagName: [''],
            placeholder: [''],
            formArray: undefined,
            validators: [
              {
                email: false,
                maxLength: undefined,
                patternMessage: undefined,
                minLength: undefined,
                pattern: undefined,
                max: undefined,
                min: undefined,
                required: false,
                exists: undefined,
                existsMessage: undefined,
              },
            ],
            cssStyles: [{}],
            multiSelectList: undefined,
            dependentFormControls: undefined,
          },
        ],
      },
      {
        tabName: 'Association',
        tabElements: [
          {
            controlTypes: ['label', 'span'],
            acceptedTypes: [undefined, undefined],
            controlFlags: [false, false],
            labels: undefined,
            currentValues: ['Persona', ''],
            controlNames: [undefined, 'PersonaName'],
            classNames: ['pl-4', 'col-sm-2'],
            parentClassName: 'row',
            options: undefined,
            disabled: undefined,
            readonly: undefined,
            tagName: undefined,
            placeholder: undefined,
            formArray: undefined,
            validators: [
              {
                email: undefined,
                patternMessage: undefined,
                maxLength: undefined,
                minLength: undefined,
                pattern: undefined,
                max: undefined,
                min: undefined,
                required: undefined,
                exists: undefined,
                existsMessage: undefined,
              },
              {
                email: undefined,
                maxLength: undefined,
                minLength: undefined,
                pattern: undefined,
                patternMessage: undefined,
                max: undefined,
                min: undefined,
                required: undefined,
                exists: undefined,
                existsMessage: undefined,
              },
            ],
            cssStyles: [{}, { color: '#23A393' }],
            multiSelectList: undefined,
            dependentFormControls: undefined,
          },
          {
            controlTypes: ['dropdown'],
            acceptedTypes: ['multi'],
            controlFlags: [false],
            labels: ['Organization Type'],
            currentValues: [''],
            controlNames: ['OrganizationTypeIds'],
            classNames: ['col-sm-4'],
            parentClassName: 'row',
            options: [this.organizationTypes],
            disabled: undefined,
            readonly: undefined,
            tagName: undefined,
            placeholder: ['Select Organization Type'],
            formArray: undefined,
            validators: [
              {
                email: undefined,
                maxLength: undefined,
                minLength: undefined,
                pattern: undefined,
                patternMessage: undefined,
                max: undefined,
                min: undefined,
                required: true,
                exists: undefined,
                existsMessage: undefined,
              },
            ],
            cssStyles: [{}],
            multiSelectList: undefined,
            dependentFormControls: undefined,
          },
          {
            controlTypes: ['multiSelectListGroup'],
            acceptedTypes: [undefined],
            controlFlags: [false],
            labels: [['Roles']],
            currentValues: [[]],
            controlNames: ['Roles'],
            classNames: ['col-sm-12'],
            parentClassName: 'row',
            options: undefined,
            disabled: undefined,
            readonly: undefined,
            tagName: undefined,
            placeholder: undefined,
            formArray: {
              formArrayName: 'Roles',
              formArrayData: undefined,
              formElements: undefined,
              formGroupClassName: undefined,
              resourceLabel: undefined,
              dependentFormControls: undefined,
            },
            validators: [
              {
                email: undefined,
                patternMessage: undefined,
                maxLength: undefined,
                minLength: undefined,
                pattern: undefined,
                max: undefined,
                min: undefined,
                required: undefined,
                exists: undefined,
                existsMessage: undefined,
              },
            ],
            cssStyles: [{}],
            multiSelectList: [this.activeRoles],
            dependentFormControls: undefined,
          },
        ],
      },
    ];

    return formData;
  }

  getEditPersonaFormData() {
    const formData: Array<DynamicForm> = [
      {
        tabName: 'Details',
        tabElements: [
          {
            classNames: ['col-sm-4', 'col-sm-4'],
            controlNames: ['PersonaName', 'Alias'],
            controlTypes: ['floatingText', 'floatingText'],
            acceptedTypes: ['text', 'text'],
            controlFlags: [false, false],
            currentValues: [
              typeof this.personaData === typeof undefined
                ? ''
                : this.personaData['personaName'],
              typeof this.personaData === typeof undefined
                ? ''
                : this.personaData['alias'],
            ],
            disabled: [false, false],
            formArray: undefined,
            labels: ['Persona Name', 'Alias'],
            options: undefined,
            parentClassName: 'row',
            placeholder: ['', ''],
            readonly: [false, false],
            tagName: undefined,
            validators: [
              {
                email: false,
                maxLength: 30,
                patternMessage:
                  'Persona Name will accept only (space,_,-) and it should not start with numbers and special characters.',
                minLength: 2,
                pattern: '^(?=[a-zA-Zs].*)[a-zA-Z0-9_ d!-]+$',
                max: undefined,
                min: undefined,
                required: true,
                exists: undefined,
                existsMessage: undefined,
              },
              {
                email: false,
                maxLength: 10,
                minLength: 2,
                pattern: '^(?=[a-zA-Zs].*)[a-zA-Z0-9_d!-]+$',
                max: undefined,
                min: undefined,
                patternMessage:
                  'Alias will accept only (_,-) and it should not start with numbers and special characters.',
                required: true,
                exists: undefined,
                existsMessage: undefined,
              },
            ],
            cssStyles: [{}],
            multiSelectList: undefined,
            dependentFormControls: undefined,
          },
          {
            classNames: ['col-sm-4'],
            controlNames: ['Identifier'],
            controlTypes: ['floatingText'],
            acceptedTypes: ['text'],
            controlFlags: [false],
            currentValues: [
              typeof this.personaData === typeof undefined
                ? ''
                : this.personaData['identifier'],
            ],
            disabled: [false],
            formArray: undefined,
            labels: ['Identifier'],
            options: [undefined],
            parentClassName: 'row',
            placeholder: [''],
            readonly: [false],
            tagName: [undefined],
            validators: [
              {
                email: false,
                required: true,
                pattern: '^(?=[a-zA-Zs].*)[a-zA-Z0-9_d!-]+$',
                patternMessage:
                  'Identifier will accept only (_,-) and it should not start with numbers and special characters.',
                max: undefined,
                min: undefined,
                minLength: 2,
                maxLength: 35,
                exists: undefined,
                existsMessage: undefined,
              },
            ],
            cssStyles: [{}],
            multiSelectList: undefined,
            dependentFormControls: undefined,
          },
          {
            classNames: ['col-sm-4'],
            controlNames: ['Description'],
            controlTypes: ['textArea'],
            acceptedTypes: [undefined],
            controlFlags: [false],
            currentValues: [
              typeof this.personaData === typeof undefined
                ? ''
                : this.personaData['description'],
            ],
            disabled: [false],
            formArray: undefined,
            labels: ['Description'],
            options: [undefined],
            parentClassName: 'row',
            placeholder: [''],
            readonly: [false],
            tagName: [undefined],
            validators: [
              {
                email: false,
                required: true,
                patternMessage: undefined,
                pattern: undefined,
                max: undefined,
                min: undefined,
                minLength: 2,
                maxLength: 200,
                exists: undefined,
                existsMessage: undefined,
              },
            ],
            cssStyles: [{}],
            multiSelectList: undefined,
            dependentFormControls: undefined,
          },
          {
            controlTypes: ['toggleButton'],
            acceptedTypes: [undefined],
            controlFlags: [false],
            labels: [''],
            currentValues: [
              typeof this.personaData === typeof undefined
                ? ''
                : this.personaData['isActive'],
            ],
            controlNames: ['IsActive'],
            classNames: ['col-sm-6'],
            parentClassName: 'row',
            options: [undefined],
            disabled: [undefined],
            readonly: [undefined],
            tagName: [''],
            placeholder: [''],
            formArray: undefined,
            validators: [
              {
                email: false,
                maxLength: undefined,
                minLength: undefined,
                pattern: undefined,
                max: undefined,
                min: undefined,
                patternMessage: undefined,
                required: false,
                exists: undefined,
                existsMessage: undefined,
              },
            ],
            cssStyles: [{}],
            multiSelectList: undefined,
            dependentFormControls: undefined,
          },
        ],
      },
      {
        tabName: 'Associations',
        tabElements: [
          {
            controlTypes: ['label', 'span'],
            acceptedTypes: [undefined, undefined],
            controlFlags: [false, false],
            labels: undefined,
            currentValues: [
              'Persona',
              typeof this.personaData === typeof undefined
                ? ''
                : this.personaData['personaName'],
            ],
            controlNames: [undefined, 'PersonaName'],
            classNames: ['pl-4', 'col-sm-2'],
            parentClassName: 'row',
            options: undefined,
            disabled: undefined,
            readonly: undefined,
            tagName: undefined,
            placeholder: undefined,
            formArray: undefined,
            validators: [
              {
                email: undefined,
                maxLength: undefined,
                minLength: undefined,
                pattern: undefined,
                max: undefined,
                patternMessage: undefined,
                min: undefined,
                required: undefined,
                exists: undefined,
                existsMessage: undefined,
              },
            ],
            cssStyles: [{}, { color: '#23A393' }],
            multiSelectList: undefined,
            dependentFormControls: undefined,
          },
          {
            controlTypes: ['dropdown'],
            acceptedTypes: ['multi'],
            controlFlags: [false],
            labels: ['Organization Type'],
            currentValues: [this.selectedOrgTypes],
            controlNames: ['OrganizationTypeIds'],
            classNames: ['col-sm-4'],
            parentClassName: 'row',
            options: [this.organizationTypes],
            disabled: undefined,
            readonly: undefined,
            tagName: undefined,
            placeholder: ['Select Organization Type'],
            formArray: undefined,
            validators: [
              {
                email: undefined,
                maxLength: undefined,
                patternMessage: undefined,
                minLength: undefined,
                pattern: undefined,
                max: undefined,
                min: undefined,
                required: true,
                exists: undefined,
                existsMessage: undefined,
              },
            ],
            cssStyles: [{}],
            multiSelectList: undefined,
            dependentFormControls: undefined,
          },
          {
            controlTypes: ['multiSelectListGroup'],
            acceptedTypes: [undefined],
            controlFlags: [false],
            labels: ['Roles'],
            currentValues: [
              typeof this.personaData === typeof undefined
                ? ''
                : this.personaData['roles'],
            ],
            controlNames: ['Roles'],
            classNames: ['col-sm-12'],
            parentClassName: 'row',
            options: undefined,
            disabled: undefined,
            readonly: undefined,
            tagName: undefined,
            placeholder: undefined,
            formArray: {
              formArrayName: 'Roles',
              formArrayData: undefined,
              formElements: undefined,
              formGroupClassName: undefined,
              resourceLabel: undefined,
              dependentFormControls: undefined,
            },
            validators: [
              {
                email: undefined,
                maxLength: undefined,
                minLength: undefined,
                pattern: undefined,
                max: undefined,
                patternMessage: undefined,
                min: undefined,
                required: undefined,
                exists: undefined,
                existsMessage: undefined,
              },
            ],
            cssStyles: [{}],
            multiSelectList: [this.associatedRoles],
            dependentFormControls: undefined,
          },
        ],
      },
    ];

    return formData;
  }
}
