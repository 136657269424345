import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, forkJoin, of, throwError } from 'rxjs';
import { DocGenModalComponent } from './doc-gen-modal.component';
import { HttpClient } from '@angular/common/http';
import { catchError, delay, map, mergeMap, retryWhen, take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class DocGenService {

  constructor(private dialog: MatDialog, private http: HttpClient, private toastr: ToastrService) { }

  openModal(quoteId: string, name?: string) {
    const dialogRef: MatDialogRef<DocGenModalComponent> = this.dialog.open(DocGenModalComponent, {
      width: '400px',
      data: { quoteId: quoteId , name: name},
    });
  }

  createPayloadForCADGeneration(configOptions, domain, format, productId): any {
    const cadPayload = {};

    cadPayload["d"] = domain;
    cadPayload["id"] = productId;
    cadPayload["f"] = format;

    Object.entries(configOptions).forEach(([key, value], index) => {
      const paramKey = `p${index}`;
      const valueKey = `v${index}`;
      const typeKey = `t${index}`;

      cadPayload[paramKey] = key;
      cadPayload[valueKey] = value;
      cadPayload[typeKey] = 'string';
    });

    cadPayload["o"] = configOptions["PART_NO"]

    return cadPayload;
  }

  convertPayloadToQueryString(data: Record<string, string | number | boolean>): string {
    const queryStringArray = [];

    for (const [key, value] of Object.entries(data)) {
      queryStringArray.push(`${encodeURIComponent(key)}=${encodeURIComponent(value.toString())}`);
    }

    return queryStringArray.join('&');
  }

  getJobIdFromCADAPI(queryString: string): Observable<any> {
    return this.http.get<any>(`https://product-config.net/catalog3/cad?${queryString}`);
  }

  getResultUrlFromVisualizationAPI(jobId: string): Observable<string> {
    const apiUrl = `https://www.product-config.net/visualization/service/jobs/${jobId}`;

    return new Observable(observer => {
      const poll = () => {
        this.http.get(apiUrl).subscribe(
          (data: any) => {
            if (data?.job?.errorText) {
              console.log('Unable to complete CAD job.');
            } else if (data?.job?.resultUrl) {
              console.log('CAD job completed: ' + data.job.resultUrl);
              observer.next(data?.job?.resultUrl);
              observer.complete();
            } else {
              setTimeout(poll, 5000);
            }
          },
          error => {            
            console.error('Error fetching CAD job status:', error);
            observer.error(error);
          }
        );
      };

      poll();
    });

  }

  createDataTransferFromUrls(urls: string[]): Observable<DataTransfer> {
    const requests = urls.map((url, index) =>
      this.http.get(url, { responseType: 'blob' }).pipe(
        mergeMap((response: Blob) => {
          const fileName = `file${index + 1}.pdf`;
          const file = new File([response], fileName, { type: 'application/pdf' });
          return of(file);
        })
      )
    );

    return forkJoin(requests).pipe(
      mergeMap((files: File[]) => {
        const dataTransfer = new DataTransfer();
        files.forEach(file => {
          dataTransfer.items.add(file);
        });
        return of(dataTransfer);
      })
    );
  }

}