import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MSALService } from '@cpq-app/shared/services/msal.service';
import { AlertService } from '@cpq-app/shared/services/alert.service';
import { AppConfig } from '@cpq-app/config/app.config';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsersService } from '@cpq-app/adminstration/users/users.service';
import { CartService } from '@cpq-app/services/cart.service';
import { forkJoin, of } from 'rxjs';
import { HeaderService } from './header.service';
import { GuestConfigService } from '@cpq-app/adminstration/guest-config/guest-config.service';
import { LogoutService } from '@cpq-app/shared/services/logoutService';
import { StatusCodes } from 'http-status-codes';
import { environment } from '@cpq-environments/environment';
export enum SessionVariables {
  PERSONA = 'persona',
  USER_TYPE = 'userType',
  OPCO_NAME = 'opcoName'
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  headerData = {};
  userName;
  constructor(
    private router: Router,
    private appConfig: AppConfig,
    private msalService: MSALService,
    private userService: UsersService,
    private headerService: HeaderService,
    private alertService: AlertService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private cartService: CartService,
    private guestService: GuestConfigService,
    private logoutService: LogoutService
  ) { }

  ngOnInit() {
    if(!sessionStorage.getItem('salesforceToken')) {
      this.getHeaderDetails();
    }
  }

  getHeaderDetails() {
    this.spinner.show();
    const loggedInUserAdInfo = this.msalService.getUser().idToken;
    this.userService.getUser(loggedInUserAdInfo.oid).subscribe(res => {
      if (res['statusCode'] !== StatusCodes.OK) {
        this.alertService.error(`${res['message']}`)
      } else {
        let userData = res['data'];
        let headerData;
        if (userData === null) {
          if (environment.B2CConfigs.superAdminOid !== loggedInUserAdInfo.oid && loggedInUserAdInfo.OpcoName !== sessionStorage.getItem(SessionVariables.OPCO_NAME)) {
            this.toaster.error('User is not registered within application');
            this.logoutService.logout();
          }
        }
        this.headerService.getHeader(loggedInUserAdInfo.oid).subscribe(res1 => {
          if (res1['statusCode'] !== StatusCodes.OK) {
            this.alertService.error(`${res1['message']}`);
          } else {
              headerData = res1['data'];
              // this.guestService.setGuestUserDetails(userData);
              let userRole = '';
              headerData['user'] = {};
              if (userData === null) {
                userRole = 'Super Admin';
                let userName = loggedInUserAdInfo.FirstName + ' ' + loggedInUserAdInfo.LastName;
                this.headerService.setUsername(userName);
                headerData['user']['userType'] = 'superadmin';
              } else {
                userRole = userData['personaName'];
                headerData['user']['userType'] = userData['userType'];
                let userName = userData['firstName'] + ' ' + userData['lastName'];
                this.headerService.setUsername(userName);
                sessionStorage.setItem('privacyTerms', userData['privacyTerm']?.isAccepted);
                sessionStorage.setItem('userName', userData['userName']);
                if (userData['privacyTerm']?.isAccepted === false || userData['privacyTerm']?.isAccepted === null ) {
                  this.router.navigate(['/privacy-terms']);
                }
                if (userData.isActive !== true) {
                  if(userData.isDeactivatedBySystem === 'true' && userData.isActive !== true && userData.userType !== 'Guest') {
                    userData.isActive = true;
                    userData.isDeactivatedBySystem = false;
                    userData.lastLoggedIn = new Date().toISOString();
                    this.activateUser(userData.oId);
                  } else {
                    this.toaster.warning('User is inactive. Please contact admin in order to login.')
                    this.logoutService.logout();
                  }
                }
                this.updateLastLoginData(userData);
              }
              this.headerService.getUserName().subscribe((name) => {
                this.userName = name;
              })
              sessionStorage.setItem('persona', userRole);
              headerData['user']['role'] = userRole;
              headerData['user']['pic'] =
                userRole === 'Super Admin'
                  ? './assets/images/administrator-profile-pic.png'
                  : userData?.profilePic?.blobUri;
            this.headerData = headerData;
            sessionStorage.setItem('userType', headerData['user']['userType']);
            }
          });
        }
        this.spinner.hide();
    });
  }

  updateLastLoginData(user) {
    user.lastLoggedIn = new Date().toISOString();
    this.userService.EditProfile(user).subscribe((res) => {
    }, err => {
       this.toaster.error(err.message);
    });
  }

  activateUser(oId) {
    this.userService.activateUser(oId).subscribe((res) => {
      window.location.reload();
    }, err => {
        this.toaster.error(err.message);
    });
  }
}
