import { Component, OnInit, OnDestroy } from '@angular/core';
import { MSALService } from './shared/services/msal.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { ToastrService } from 'ngx-toastr';
import { LogoutService } from './shared/services/logoutService';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private idleState = 'Not started.';
  title = 'app';
  isIdle: boolean;
  timedOut: boolean;
  toastRef : any;

  constructor(
    private router: Router,
    public msalService: MSALService,
    private spinner: NgxSpinnerService,
    private idle: Idle,
    private toastr: ToastrService,
    private logOutService: LogoutService
  ) {}

  ngOnInit() {
    this.spinner.show();
    this.isIdle = false;
    this.idle.setIdle(1200);
    this.idle.setTimeout(120);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    const currentURL = window.location.href;
    if(currentURL.includes('opportunity') && currentURL.includes('token')) {
       const token = document.URL.match('\\s*token\\s*=\\s*([^;]+)')?.pop() || '';
       this.msalService.accessToken = token;
       console.log(this.msalService.accessToken);
       sessionStorage.setItem('salesforceToken', token);
    }
    this.idle.onIdleEnd.subscribe(() => {
      this.toastr.clear(this.toastRef.ToastId);
      this.idleState = 'No longer idle.';
    });
    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.logOutService.logout();
    });
    this.idle.onIdleStart.subscribe(() => {
      this.idleState = 'You have gone idle!';
    });
    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
        this.toastRef = this.toastr.warning(
          'Your session is going to timeout in less than 2 min. If you are still working in your session, please click to continue session.',
          'Alert!',
          {
            timeOut: 2*60*1000 //equal to 2 min
          }
        );
    });
    this.reset();
    window.addEventListener('storage', this.storageChange, false);
  }

  ngOnDestroy() {
    this.idle.stop();
  }

  storageChange(event) {
    if (event.key === 'logged_in' && event.newValue === 'false') {
      sessionStorage.clear();
      window.location.href = '/';
    }
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }
}
