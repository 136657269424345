import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GuestConfigService } from '@cpq-app/adminstration/guest-config/guest-config.service';
import { environment } from '@cpq-environments/environment';
import { BehaviorSubject, forkJoin, Observable, of, ReplaySubject, Subject, Subscriber, Subscription, throwError } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { Family } from '../models/family.model';
import { LoginService } from './login.service';
import { cadParamGroups } from './product.service';

export interface QueryResult<T> {
  done: boolean;
  records: Array<T>;
  size: number;
  cursorid?: string;
}

export interface CrmExport {
  messages?: CpqMessages[];
}

export interface OppAccountResult {
  Id?: string;
  ExternalId?: string;
  Account?: CpqAccount;
}

export interface action {
  entity: string;
  name?: string;
  format?: string;
  id?: string;
}
export interface CpqProfiles {
  id: string;
  name: string;
  parentId?: string;
  externalId: string;
  PermissionSsoUser?: boolean;
}

interface CpqObjectParams {
  withAcl?: string;
  resolveNames?: string;
  refreshAvailableProducts?: boolean;
}

export interface CpqProposalData {
  CurrentDate?: string;
  User?: any;
  Opportunity?: any;
  Organization?: any;
}

export interface NestedQueryResult<T> {
  records: Array<T>;
  size: number;
}

export interface CpqApiResult {
  success: boolean;
  results: any[];
}

export interface CpqSaveConfigResult {
  productId: string;
  quoteProductId: string;
}

export interface CpqReOpenConfigResult {
  productId: string;
  configId: string;
  readOnly: boolean;
  currency: string;
}

interface CpqMessages {
  code: string;
  level: string;
  context: any; // Dynamic return
}

interface CpqChanges {
  id: string;
  op: string;
  attributeChanges?: any;
}

export interface CpqObjectUpdateResult {
  Id?: string;
  Name?: string;
  Products?: CpqProductObjectResult[]
  messages?: CpqMessages[];
  changes?: CpqChanges[];
}

interface CpqProductObjectResult {
  BaseUnitPrice: string;
  Image: string;
  LargeImage: string;
  Name: string;
  ProductId: string
}

const CPQ_API = {
  PROPOSAL: 'proposal',
  CONFIG: 'config',
  OPTIONS: 'options',
  CRM: 'crm',
  EXPORT: 'export',
  ATTACH: 'attach',
  OBJECT: 'object'
};

export enum CPQ_EXPORT_STATUS {
  COMPLETED = 'Completed',
  NO = 'NotExported'
};

export enum CpqObjects {
  Opportunity = 'opportunity',
  Opportunities = 'opportunity',
  Quote = 'quote',
  Quotes = 'quote',
  Account = 'account',
  Accounts = 'account',
  Partners = 'partner',
  Partner = 'partner',
  Roles = 'role',
  Users = 'user',
  User = 'user',
  Profiles = 'profile',
  Favorites = 'favorite',
  QuoteLine = 'quoteline',
}

export enum userRole {
  internal = 'Internal',
  guest = 'Guest',
  external = 'External',
  admin = 'Admin'
}

export class CpqAccount {
  static queryFields = ['Id', 'Name', 'ExternalId', 'AccountNumber'];
  Id: string;
  Name: string;
  ExternalId: string;
  AccountNumber: string;
}

export class CpqProposal {
  static queryFields = ['Id', 'Name'];
  Id: string;
  Name: string;

  static getQueryString(): string {
    return `SELECT ${this.queryFields.join(', ')} FROM Proposal`;
  }

  static getNestedQueryString(): string {
    return `(SELECT ${this.queryFields.join(', ')} FROM Proposals)`;
  }
}

export class CpqQuoteline {
  static guestQueryFields = ['Id', 'Name', 'WorkflowStatus', 'CreatedDate',
    'FormattedId', 'Note', 'ExpirationDate', 'ProductId',
  ];

  static queryFields = CpqQuoteline.guestQueryFields.concat(
    ['Selling_Price__c', 'TotalSellingPrice', 'UnitSellingPrice',
    ]);


  Id: string;
  Name: string;
  Quantity: number;
  WorkflowStatus: string;
  CreatedDate: string | Date;
  FormattedId: string;
  Note: string;
  ExpirationDate: string | Date;
  ProductId: string;
  // tslint:disable-next-line: variable-name
  Selling_Price__c?: number;
  TotalSellingPrice?: number;
  UnitSellingPrice?: number;
  SelectedProduct: {
    MinQuantity: number;
  }

  static getQueryString(): string {
    return `SELECT ${this.queryFields.join(', ')} FROM Quoteline`;
  }

  static getNestedQueryString(): string {
    return `(SELECT ${this.queryFields.join(', ')} FROM Quotelines)`;
  }
}

export enum QuoteWorkflowStatus {
  InProgress = 'InProgress',
  Approved = 'Approved',
  Completed = 'Completed',
  AutoApproved = 'AutoApproved',
  NoApprovalRequired = 'NoApprovalRequired',
  Waiting = 'Waiting',
  None = 'None',
  Invalid = 'Invalid',
  Canceled = 'Canceled',
  Aborted = 'Aborted',
  ObjectDeleted = 'ObjectDeleted',
  Rejected = 'Rejected',
}

export class CpqQuote {
  static queryFields = ['Id', 'Name', 'WorkflowStatus', 'LastModifiedDate',
    'FormattedId', 'Note', 'Selling_Price__c', 'ExpirationDate',
    CpqProposal.getNestedQueryString(),
  ];

  static guestQueryFields = ['Id', 'Name', 'WorkflowStatus', 'LastModifiedDate',
    'FormattedId', 'Note', 'ExpirationDate',
    CpqProposal.getNestedQueryString(),
  ];

  Id: string;
  Name: string;
  WorkflowStatus: QuoteWorkflowStatus;
  CreatedDate: string;
  FormattedId: string;
  Note: string;
  ExpirationDate: string | Date;

  // These fields may not be available to guests
  // tslint:disable-next-line: variable-name
  Selling_Price__c?: string;


  // These are nested objects
  QuoteLines: QueryResult<CpqQuoteline>;
  quoteLines?: CpqQuoteline[];

  QuoteConfiguredProducts?: QueryResult<any>;
  products?: any[];

  Proposals?: QueryResult<CpqProposal>;
  proposal?: CpqProposal;

  // tslint:enable: variable-name

  static getQueryString(): string {
    return `SELECT ${this.queryFields.join(', ')} FROM Quote`;
  }

  static getNestedQueryString(): string {
    return `(SELECT ${this.queryFields.join(', ')} FROM Quotes)`;
  }

  /**
   * Retruns `true` if the quote object has a closed workflow state
   * @param quote as `CpqQuote`
   */
  static checkIsClosed(quote: CpqQuote) {
    switch (quote?.WorkflowStatus) {
      case QuoteWorkflowStatus.None:
      case QuoteWorkflowStatus.NoApprovalRequired:
      case QuoteWorkflowStatus.InProgress:
      case QuoteWorkflowStatus.Aborted:
      case QuoteWorkflowStatus.Canceled:
      case QuoteWorkflowStatus.Invalid:
      case QuoteWorkflowStatus.Rejected:
        return false;

      case QuoteWorkflowStatus.Completed:
      case QuoteWorkflowStatus.Approved:
      case QuoteWorkflowStatus.Waiting:
      case QuoteWorkflowStatus.AutoApproved:
      default:
        return true;
    }
  }

  constructor(maybeQuote?: CpqQuote) {
    // console.log('%c*** Made a CpqQuote', 'background-color:red');
    this.Id = maybeQuote?.Id;
    this.Name = maybeQuote?.Name;
    this.WorkflowStatus = maybeQuote?.WorkflowStatus;
  }

  get id() {
    return this.Id;
  }

  get name() {
    return this.Name;
  }

  get workflowStatus() {
    return this.WorkflowStatus;
  }

  get isClosed(): boolean {
    switch (this.WorkflowStatus) {
      case QuoteWorkflowStatus.None:
      case QuoteWorkflowStatus.NoApprovalRequired:
      case QuoteWorkflowStatus.InProgress:
      case QuoteWorkflowStatus.Aborted:
      case QuoteWorkflowStatus.Canceled:
      case QuoteWorkflowStatus.Invalid:
      case QuoteWorkflowStatus.Rejected:
        return false;

      case QuoteWorkflowStatus.Completed:
      case QuoteWorkflowStatus.Approved:
      case QuoteWorkflowStatus.Waiting:
      case QuoteWorkflowStatus.AutoApproved:
      default:
        return true;
    }
  }
}


interface CpqOpenConfigResult {
  configAlias: string;
  datasetGUID: string;
  datasetPublishedDateTime: string;
  success: true;
  configId: string;
  currency: string;
  openConnections: number;
  maxConnections: number;
}

export interface CpqProductUpdate {
  update?: boolean;
  status?: CpqProductUpdateStatus;
  type?: 'PRODUCTS_UPDATE';
  products?: string[];
}

export enum CpqProductUpdateStatus {
  WAITING = 'waiting',
  WORKING = 'working',
  CANCELING = 'canceling',
  CANCELED = 'canceled',
  COMPLETE = 'complete',
  FATAL_ERROR = 'fatal',
}

export enum CrmExportStatus {
  NotExported = 'NotExported',
  Completed = "Completed"
}

export class CpqOpportunity {
  Id: string;
  Name: string;
  quotes?: CpqQuote[];

  constructor(mayOpp?: CpqOpportunity) {
    this.Id = mayOpp?.Id;
    this.Name = mayOpp?.Name;
    this.quotes = mayOpp?.quotes?.map(q => new CpqQuote(q));
  }

  // camelCase accessors
  get id(): string { return this.Id; }
  set id(newId: string) { this.Id = newId; }
  get name(): string { return this.Name; }
  set name(newName: string) { this.Name = newName; }

}

class QueryCpqOpportunity {
  static queryFields = ['Id', 'Name', CpqQuote.getNestedQueryString()];

  Id: string;
  Name: string;
  Quotes?: NestedQueryResult<CpqQuote>;

  static getQueryString(): string {
    return `SELECT ${this.queryFields.join(', ')} FROM Opportunity`;
  }

  getOpportunity(): CpqOpportunity {
    const opp = new CpqOpportunity();
    opp.Id = this.Id;
    opp.Name = this.Name;
    opp.quotes = this.Quotes?.records?.map(q => new CpqQuote(q));

    return opp;
  }
}


interface CpqUser {
  Id: string;
  Username: string;
  FirstName: string;
  LastName: string;
  IsActive: boolean;
  ProfileId: string;
  UserRoleId?: string;
  PartnerId?: string;
  IsSharedAnonymous: boolean;
  IsTemplateAnonymous: boolean;
}

@Injectable({
  providedIn: "root",
})
export class CartService {
  // Local Storage symbols
  readonly USER_ID = "userId";
  readonly USERNAME = "username";

  oppurtunityId: string;
  quoteId: string;
  rfst = this.loginService.getRfst();
  correlationId: string;
  configId: string;

  private sharedGuid = new BehaviorSubject("");
  currentGuidData = this.sharedGuid.asObservable();
  private backendUrl = environment.B2CConfigs.BackendURL;
  enableSubmitQuote = new Subject();
  updateCart = new Subject();
  reConfiguration = new Subject();
  //TODO: REMOVE
  internalUser = true;

  readonly quoteFields = [
    "Id",
    "CreatedDate",
    "FormattedId",
    "Name",
    "Note",
    "Selling_Price__c",
    "ExpirationDate",
  ];

  readonly productFields = [
    "NodeIndex",
    "OpportunityId",
    "graphicName__c",
    "Model_Code__c",
    "Proposal_Notes__c",
    "Actual_Unit_List_Price__c",
    "TotalList",
  ];

  readonly quoteLineFields = [
    "ProductId",
    "Description",
    "ExtendedDescription",
    "Name",
    "Quantity",
    "TotalSellingPrice",
    "UnitSellingPrice",
  ];

  readonly guestQuoteLineFields = [
    "ProductId",
    "Description",
    "ExtendedDescription",
    "Name",
    "Quantity",
  ];

  quoteSubjects = new Map<string, ReplaySubject<CpqQuote>>();
  quoteUpdateInFlight = new Map<string, Observable<any>>();

  constructor(
    private http: HttpClient,
    private guestService: GuestConfigService,
    private loginService: LoginService
  ) {}

  store(key, value) {
    sessionStorage.setItem(key, value);
  }
  retrive(key) {
    return sessionStorage.getItem(key);
  }
  remove(key) {
    sessionStorage.removeItem(key);
  }

  /**
   * Asynchronously obtains a collection of the available product families (aka datasets).
   * @return an `Observable` for the `Array` of `Family` objects
   */
  fetchFamilies(quoteId: string): Observable<Family[]> {
    const url = this.cpqUrl("cpaas", "ui", "datasets");
    const params = new HttpParams().set("quoteId", quoteId);

    return new Observable<Family[]>((observer) => {
      const subscription = this.http
        .get<CpqApiResult>(url, { params, withCredentials: true })
        .subscribe((resp) => {
          if (resp.success && resp.results) {
            const families = resp.results;
            observer.next(families);
            observer.complete();
          } else {
            observer.error("There were no valid families");
          }
        });

      return {
        unsubscribe: () => subscription.unsubscribe(),
      };
    });
  }

  publicationForQuoteWithProducts(quoteId: string): Subject<CpqQuote> {
    if (!this.quoteSubjects.has(quoteId)) {
      this.quoteSubjects.set(quoteId, new ReplaySubject<CpqQuote>(1));
      // Subscribe to the update in order to trigger the subject creation
      // however the resulting subscription itself is unneeded.
      this.updateQuoteData(quoteId).subscribe();
    }

    return this.quoteSubjects.get(quoteId);
  }

  updateQuoteData(quoteId: string): Observable<void> {
    let returnObservable: Observable<void>;

    if (!this.quoteUpdateInFlight.has(quoteId)) {
      returnObservable = this.fetchCpqQuoteWithProducts(quoteId).pipe(
        map((data) => {
          this.quoteSubjects.get(quoteId).next(data);
          this.quoteUpdateInFlight.delete(quoteId);
        }),
        tap({
          error: () => console.log(`%cError getting quote data`, "color:red"),
        })
      );

      this.quoteUpdateInFlight.set(quoteId, returnObservable);
    } else {
      console.log(`Update in flight for ${quoteId}`);
      returnObservable = this.quoteUpdateInFlight.get(quoteId);
    }

    return returnObservable;
  }

  /**
   * Asynchronously obtains a Quote with it's collection of Products.
   * @return an `Observable` for the `Quote` object.
   */
  fetchCpqQuoteWithProducts(quoteId: string): Observable<CpqQuote> {
    const loggedInUser = this.guestService.getGuestUserDetails();
    const quoteFields = loggedInUser.isGuest
      ? CpqQuote.guestQueryFields
      : CpqQuote.queryFields;
    const url = `${this.backendUrl}/cpq/fetchCpqQuoteWithProducts?`;
    const params = new HttpParams()
      .set("quoteId", quoteId)
      .set("isGuest", loggedInUser.isGuest);

    return new Observable<any>((observer) => {
      const subscription = this.http
        .get<any>(url, { params, withCredentials: true })
        .subscribe(
          (rawQuotes) => {
            console.log(
              `%cDATA%c for quotes`,
              "background-color:black; color:white;",
              "color:black",
              rawQuotes
            );
            const rawQuote = rawQuotes[0];
            const quote = {
              quoteLines: rawQuote.QuoteLines?.records || [],
              products: rawQuote.QuoteConfiguredProducts?.records || [],
              proposal: rawQuote.Proposals?.size
                ? rawQuote.Proposals.records[0]
                : undefined,
            };

            quoteFields.forEach((field) => {
              quote[field] = rawQuote[field];
            });

            observer.next(quote);
            observer.complete();
          },
          (err) => {
            // FIXME error handling
            console.log("Failed to fetch the quote with products", err);
            observer.error("Failed to fetch the quote with products");
          }
        );

      return {
        unsubscribe: () => subscription.unsubscribe(),
      };
    });
  }

  private getIsoDateOffsetByMonths(numberOfMonths = 0) {
    const offsetDate = new Date();
    offsetDate.setMonth(offsetDate.getMonth() - numberOfMonths);
    return offsetDate.toISOString();
  }

  /**
   * Asynchronously obtains a collection of previous quotes.
   * @param numberOfMonths as `Number` defaults to 1
   * @returns an `Observable` of an `Array` of `Quote` objects
   */
  fetchQuotes(startDate, endDate): Observable<CpqQuote[]> {
    const loggedInUser = this.guestService.getGuestUserDetails();
    const userId = this.retrive(this.USER_ID);
    if (!userId) {
      return throwError("No valid userId"); // FIXME better error handling
    }
    //const url = `${this.backendUrl}/cpq/quotes?`
    const url = this.cdsCpqUrl("quotes");
    const params = new HttpParams()
      .set("userId", userId)
      .set("startDate", this.getStartDate(startDate))
      .set("endDate", this.getEndDate(endDate))
      .set("isGuest", loggedInUser.isGuest);

    return new Observable<CpqQuote[]>((observer) => {
      const subscription = this.http
        .get<CpqQuote[]>(url, { params, withCredentials: true })
        .subscribe(
          (rawQuotes) => {
            const quotes = rawQuotes.map<CpqQuote>((q) => {
              q.quoteLines = q.QuoteLines?.records || [];
              q.products = q.QuoteConfiguredProducts?.records || [];
              q.proposal = q.Proposals?.size
                ? q.Proposals.records[0]
                : undefined;
              return q;
            });

            observer.next(quotes);
            observer.complete();
          },
          (err) => {
            // FIXME error handling
            console.log("Failed to fetch the quote with products", err);
            observer.error("Failed to fetch the quote with products");
          }
        );

      return {
        unsubscribe: () => subscription.unsubscribe(),
      };
    });
  }

  getStartDate(startDate: string) {
    const newStartDate = new Date(startDate);
    newStartDate.setHours(0, 0, 0, 0);
    return newStartDate.toISOString();
  }

  getEndDate(endDate: string) {
    const newEndDate = new Date(endDate);
    newEndDate.setHours(23, 59, 59, 999);
    return newEndDate.toISOString();
  }
  /**
   * Asynchronous request to delete a product
   * @param productId as `String`
   * @returns an `Observable` of the request; signals success or failure.
   */
  deleteProduct(productId: string): Observable<any> {
    return this.loginService.getRfst().pipe(
      switchMap((rfst) => {
        const url = this.cpqUrl("cpq", productId);
        const params = new HttpParams().set("rfst", rfst);
        return this.http.delete(url, { params, withCredentials: true });
      })
    );
  }

  setProductQty(productId: string, qty: number): boolean {
    // cpq.update
    return true;
  }

  createOpportunityId(): Observable<any> {
    const params = [];
    const payload = {
      Name: 'New Opportunity',
      CrmExportStatus: CPQ_EXPORT_STATUS.NO
    };
    params.push(payload);
    const url = this.cdsCpqUrl("object", CpqObjects.Opportunity);
    return this.http.post<any>(url, params, { withCredentials: true });
  }

  /**
   * Asynchronously returns the most recent Opportunity Id
   */
  getMostRecentOpportunity(): Observable<any> {
    const userId = this.retrive(this.USER_ID);
    //const url = `${this.backendUrl}/cpq/getMostRecentOpportunity`;
    const url = this.cdsCpqUrl("opportunity", "latest");
    const params = new HttpParams().set("userId", userId);
    return new Observable<any>((observer) => {
      const subscription = this.http
        .get<any>(url, { params, withCredentials: true })
        .subscribe(
          (latestOpportunity) => {
            //const opportunityId = (results.length > 0) ? results[0].Id : '';
            observer.next(latestOpportunity);
            observer.complete();
          },
          (err) => {
            // FIXME add error handling or remove this and allow it to bubble up
            console.log("Failed to fetch the quote with products", err);
            observer.error("Failed to fetch the quote with products");
          }
        );

      return {
        unsubscribe: () => subscription.unsubscribe(),
      };
    });
  }

  /**
   * Obtains the CPQ query results for the specfied object type
   * @param objectType - specify object type like [accounts, roles, profiles, users, partners, opportunities,
   *    opportunityquotes, quotes, quoteproducts]; REQUIRED
   */
  getCpqObjects<T>(objectType: CpqObjects, objectId?: string): Observable<T> {
    let url = "";

    switch (objectType) {
      case CpqObjects.Accounts:
      case CpqObjects.Partners:
      case CpqObjects.Roles:
      case CpqObjects.Users:
      case CpqObjects.Profiles:
      case CpqObjects.Opportunities:
      case CpqObjects.Quotes:
      case CpqObjects.Favorites:
      case CpqObjects.QuoteLine:
        url = this.cdsCpqUrl("object", objectType, objectId);
        break;

      default:
        return throwError(
          "Please verify the specified Object Type as its invalid"
        );
    }

    const apiResult = this.http.get<T>(url, { withCredentials: true });
    return apiResult;
  }

  /**
   * Asynchronously returns the most recent Opportunity Id
   */
  getMostRecentOpportunityAndQuote(): Observable<CpqOpportunity> {
    const userId = this.retrive(this.USER_ID);
    const url = `${this.backendUrl}/cpq/getMostRecentOpportunityAndQuote`;
    const params = new HttpParams().set("userId", userId);

    return new Observable<CpqOpportunity>((observer) => {
      const subscription = this.http
        .get<QueryCpqOpportunity>(url, { params, withCredentials: true })
        .subscribe(
          (opps) => {
            let opp = new CpqOpportunity();
            if (opps) {
              opp = opps[0].getOpportunity();
            }

            observer.next(opp);
            observer.complete();
          },
          (err) => {
            // FIXME add error handling or remove this and allow it to bubble up
            console.log("Failed to fetch the quote with products", err);
            observer.error("Failed to fetch the quote with products");
          }
        );

      return {
        unsubscribe: () => subscription.unsubscribe(),
      };
    });
  }

  getMostRecentQuoteIdOnOpp(oppurtunityId: string): Observable<CpqQuote[]> {
    const url = `${this.backendUrl}/cpq/getMostRecentQuoteIdOnOpp`;
    const params = new HttpParams().set("oppId", oppurtunityId);
    return new Observable<CpqQuote[]>((observer) => {
      const subscription = this.http
        .get<CpqQuote[]>(url, { params, withCredentials: true })
        .subscribe(
          (results) => {
            observer.next(results);
            observer.complete();
          },
          (err) => {
            // FIXME add error handling or remove this and allow it to bubble up
            console.log(err);
          }
        );

      return {
        unsubscribe: () => subscription.unsubscribe(),
      };
    });
  }

  createOpportunity(): Observable<CpqOpportunity> {
    throw new Error("Method Not Implemented");
    return of(new CpqOpportunity());
  }

  createQuote(oppId: string): Observable<CpqQuote> {
    throw new Error("Method Not Implemented");
    return of(new CpqQuote());
  }

  /**
   * To get all the CPQ Profiles
   */
  getCPQProfiles(isPartner: boolean): Observable<any> {
    //const url = `${this.backendUrl}/cpq/getProfiles`;
    const url = this.cdsCpqUrl("object", CpqObjects.Profiles);
    const params = new HttpParams().set("partner", isPartner.toString());
    return this.http.get(url, { params, withCredentials: true });
  }

  /**
   * To get the CPQ Profiles
   */
  getCpqProfiles(filter): Observable<any> {
    return new Observable((observer) => {
      const subscription = this.getCpqObjects<CpqProfiles>(
        CpqObjects.Profiles,
        filter
      ).subscribe(
        (profiles) => {
          observer.next(profiles);
        },
        (err) => {
          console.log("Failed to fetch the profiles", err);
          observer.error("Failed to fetch the profiles");
        },
        () => {
          observer.complete();
        }
      );
      return {
        unsubscribe: () => subscription.unsubscribe(),
      };
    });
  }

  /**
   * To get all the CPQ Roles
   */
  getCPQRoles(isPartner: boolean): Observable<any> {
    //const url = `${this.backendUrl}/cpq/getRoles`;
    const url = this.cdsCpqUrl("object", CpqObjects.Roles);
    const params = new HttpParams().set("partner", isPartner.toString());
    return this.http.get(url, { params, withCredentials: true });
  }
  /**
   * To get all the CPQ distributor Partners
   */

  getCPQOrgs(isPartner: boolean): Observable<any> {
    //const url = `${this.backendUrl}/cpq/getRoles`;
    const url = this.cdsCpqUrl("object", CpqObjects.Partners);
    const params = new HttpParams().set("partner", isPartner.toString());
    return this.http.get(url, { params, withCredentials: true });
  }

  /**
   * Creates an object
   * @param objectType a `string` of the CPQ object type
   * @param object an `object` containing the fields and values to initialize the object
   * @returns an `Observable` of the resulting object id `string`
   */
  createObject(objectType: string | CpqObjects, object: any): Observable<any> {
    if (!objectType) {
      return throwError("A valid object type is required to create the Object");
    }

    if (!object) {
      return throwError("Object fields are required");
    }
    const url = this.cdsCpqUrl("object", objectType);
    //object.type = objectType;

    return new Observable<any>((observer) => {
      const subscription = this.http
        .post<CpqObjectUpdateResult>(url, object, { withCredentials: true })
        .subscribe(
          (resp) => {
            console.log("Creating objects");
            console.log(resp);
            const createdId = resp?.Id;
            if (createdId) {
              observer.next(resp);
            } else {
              observer.error(resp[0]?.messages);
            }
            observer.complete();
          },
          (err) => {
            console.log("Failed to update the Object", err);
            observer.error(err);
          }
        );

      return {
        unsubscribe: () => subscription.unsubscribe(),
      };
    });
  }

  /**
   * Method to update the CPQ object
   * @param objectId a `string` of the object id like quoteid, opportunityid, userId..etc
   * @param objectFields an `object` containing the fields and values to initialize the object
   * @returns an `Observable` of the object id `string`
   */
  updateObjectId(objectId: string, objectFields: any): Observable<string> {
    if (!objectId) {
      return throwError("A valid ID is required to update the Object");
    }
    const url = this.cpqUrl("cpq", objectId);

    return new Observable((observer) => {
      const subscription = this.http
        .put<CpqObjectUpdateResult>(url, objectFields, {
          withCredentials: true,
        })
        .subscribe(
          (resp) => {
            if (resp?.Id) {
              observer.next(resp.Id);
            } else {
              observer.error(resp?.messages);
            }
            observer.complete();
          },
          (err) => {
            console.log("Failed to update the Object", err);
            observer.error(err);
          }
        );

      return {
        unsubscribe: () => subscription.unsubscribe(),
      };
    });
  }

  /**
  * Method to update the CPQ object
  * @param objectId a `string` of the object id like quoteid, opportunityid, userId..etc
  * @param objectFields an `object` containing the fields and values to initialize the object
  * @returns an `Observable` of the object id `string`
  */
  getCPQObjectById<T>(objectType: CpqObjects, objectId: string, parameters?: CpqObjectParams): Observable<T> {
    if (!objectId) {
      return throwError('A valid ID is required to update the Object');
    }

    if (!objectType) {
      return throwError('A valid Object type required to update the Object');
    }

    let params = new HttpParams();
    if (parameters) {
      Object.keys(parameters).forEach(key => {
        params = params.set(key, parameters[key]);
      });
    }
    const url = this.cdsCpqUrl('object', objectType, objectId);

    return new Observable(observer => {
      const subscription = this.http.get<any>(url, { params, withCredentials: true }).subscribe(
        resp => {
          if (resp?.Id) {
            observer.next(resp);
          } else {
            observer.error(resp?.messages);
          }
          observer.complete();
        },
        err => {
          observer.error(err);
        }
      );

      return {
        unsubscribe: () => subscription.unsubscribe()
      };
    });
  }

  /**
   * Method to update the CPQ object
   * @param objectId a `string` of the object id like quoteid, opportunityid, userId..etc
   * @param objectFields an `object` containing the fields and values to initialize the object
   * @returns an `Observable` of the object id `string`
   */
  updateObjectById(
    objectType: CpqObjects,
    objectId: string,
    objectFields: any,
    parameters?: CpqObjectParams
  ): Observable<any> {
    if (!objectId) {
      return throwError("A valid ID is required to update the Object");
    }

    if (!objectType) {
      return throwError("A valid Object type required to update the Object");
    }
    let params = new HttpParams();
    if (parameters) {
      Object.keys(parameters).forEach((key) => {
        params = params.set(key, parameters[key]);
      });
    }
    const url = this.cdsCpqUrl("object", objectType, objectId);

    return new Observable((observer) => {
      const subscription = this.http
        .put<any>(url, objectFields, { params, withCredentials: true })
        .subscribe(
          (resp) => {
            if (resp?.Id) {
              observer.next(resp);
            } else {
              observer.error(resp?.messages);
            }
            observer.complete();
          },
          (err) => {
            console.log("Failed to update the Object", err);
            observer.error(err);
          }
        );

      return {
        unsubscribe: () => subscription.unsubscribe(),
      };
    });
  }

  /**
   * Updates the list of Object with lastest changes
   * @param objectParams `array` of objects to update the changes
   * @returns an `Observable` of each object id as `string`
   */
  updateObject(objectParams: any[]): Observable<string> {
    const url = this.cpqUrl("cpq");

    return new Observable((observer) => {
      const subscription = this.http
        .post<CpqObjectUpdateResult[]>(url, objectParams, {
          withCredentials: true,
        })
        .subscribe(
          (results) => {
            results.forEach((objResult) => {
              if (objResult?.Id) {
                observer.next(objResult.Id);
              } else {
                observer.error(objResult?.messages);
              }
            });
            observer.complete();
          },
          (err) => {
            console.log("Failed to Update the Object Id`s", err);
            observer.error("Failed to Update the Object Id`s");
          }
        );
      return {
        unsubscribe: () => subscription.unsubscribe(),
      };
    });
  }

  deleteObjectById(objectId: string): Observable<any> {
    const url = this.cpqUrl("cpq", objectId);

    return this.loginService.getRfst().pipe(
      switchMap((rfst) => {
        const params = new HttpParams().set("rfst", rfst);
        return this.http.delete(url, { params, withCredentials: true });
      })
    );
  }


  /**
 * Exports a CPQ Opportunity data into the linked CRM Opportunity
 * @param CpqOpportunityId - ID of the CPQ opportunity to linked from CRM. Only Opportunity, primary Quote data can be exported from the linked CPQ Opportunity
 * @param cpqQuoteId - ID of the CPQ primary Quote; OPTIONAL
 * @returns the staus of export from CPQ to CRM
 */
  exportToCrm(CpqOpportunityId: string): Observable<any> {
    const url = this.cdsCpqUrl(CpqOpportunityId, CPQ_API.CRM, CPQ_API.EXPORT);
    // let queryParams = new HttpParams();
    // if (cpqQuoteId) {
    //   queryParams.set('quoteId', cpqQuoteId);
    // }
    return this.http.post<any>(url, { withCredentials: true });
  }


  /**
   * Function to delete a specific object of specified object type
   * @param objectType - Type of the object
   * @param objectId  - Id of the object to delete
   * @returns
   */
  deleteObjectByObjectId(
    objectType: CpqObjects,
    objectId: string
  ): Observable<boolean> {
    const url = this.cdsCpqUrl("object", objectType, objectId);

    return new Observable((observer) => {
      const subscription = this.http
        .delete(url, { withCredentials: true })
        .subscribe({
          next: () => {
            observer.next(true);
            observer.complete();
          },
          error: (err) => {
            observer.error(err);
          },
        });

      return {
        unsubscribe: () => subscription.unsubscribe(),
      };
    });
  }

  copyObjectById(
    objectType: CpqObjects,
    objectId: string,
    newCopyName: string
  ): Observable<any> {
    const url = this.cdsCpqUrl("object", CpqObjects.Quote, objectId);
    let payload = null;
    if (newCopyName) {
      payload = {
        Name: newCopyName,
      };
    }
    return this.http.post<any>(url, payload, { withCredentials: true });
  }

  createQuoteId(oppurtunityId: string, opportunityName?: string): Observable<any> {
    // const params = [];
    const payload = {
      //type: 'Quote',
      OpportunityId: oppurtunityId,
      Name: opportunityName ? opportunityName : "New Quote",
    };
    // params.push(payload);
    const url = this.cdsCpqUrl("object", CpqObjects.Quote);
    return this.http.post<any>(url, payload, { withCredentials: true });
  }

  createQuoteWithData(params: any, oppurtunityId: string): Observable<any> {
    const payload = {
      OpportunityId: oppurtunityId,
      Name: params?.Name,
      Note: params?.Note,
      IsPrimary: 1,
    };
    const url = this.cdsCpqUrl("object", CpqObjects.Quote);
    return this.http.post<any>(url, payload, { withCredentials: true });
  }

  /**
   * Method to create the CPQ user
   * @param userFields - Object variable has the required fields to create user.
   * @return an `Observable` for the CPQ UserId
   */
  createUserId(userFields: any): Observable<any> {
    const url = this.cdsCpqUrl("object", CpqObjects.User);
    return new Observable((observer) => {
      const subscription = this.http
        .post<any>(url, userFields, { withCredentials: true })
        .subscribe(
          (resp) => {
            observer.next(resp);
            observer.complete();
          },
          (err) => {
            observer.error(err);
          }
        );

      return {
        unsubscribe: () => subscription.unsubscribe(),
      };
    });
  }

  /**
   * To get all the CPQ Users
   * SELECT City, CompanyName, Country, CreatedById, CreatedDate, DefaultCurrencyIsoCode,
   * DelegateUserId, Department, Division, Email, ExpertMode__c, ExternalAccountId, ExternalId, Fax,
   * FirstName, Id, IsActive, IsSharedAnonymous, IsTemplateAnonymous, LanguageLocaleKey,
   * LastModifiedById, LastModifiedDate, LastName, LocaleSidKey, MobilePhone, Name,
   * OpportunityLinkBehavior, PartnerId, Phone, PostalCode, ProfileId, State, Street, TimeZoneSidKey,
   * Title, Username, UserRoleId, UserType FROM User
   */
  // getCPQUsers(): Observable<any> {
  //   const url = `${this.backendUrl}/cpq/getUsers`;
  //   return this.http.get(url, { withCredentials: true });
  // }

  /**
   * To get all the CPQ Partners
   * SELECT City, Country, CreatedById, CreatedDate, Fax, Id, IsActive, LanguageLocaleKey,
   * LastModifiedById, LastModifiedDate, LocaleSidKey, ManagedByRoleId, MaxUsers, Name, Phone,
   * PostalCode, State, Street, TimeZoneSidKey FROM Partner
   */
  // getCPQPartners(): Observable<any> {
  //   const url = `${this.backendUrl}/cpq/getPartners`;
  //   return this.http.get(url, { withCredentials: true });
  // }

  /**
   * Method to create the CPQ Partner
   * @param partnerFields - Object variable has the required fields to create Partner.
   * @return an `Observable` for the CPQ PartnerId
   */
  createPartnerId(partnerFields: any): Observable<any> {
    const url = this.cdsCpqUrl("object", CpqObjects.Partner);
    return new Observable((observer) => {
      const subscription = this.http
        .post<any>(url, partnerFields, { withCredentials: true })
        .subscribe(
          (resp) => {
            observer.next(resp);
            observer.complete();
          },
          (err) => {
            console.log("Failed to Create CPQ Partner", err);
            observer.error(err);
          }
        );
      return {
        unsubscribe: () => subscription.unsubscribe(),
      };
    });
  }

  activatePartner(partnerId: any): Observable<any> {
    const params = { IsActive: true };
    const url = this.cdsCpqUrl("object", CpqObjects.Partner, partnerId);

    return new Observable((observer) => {
      const subscription = this.http
        .put<any>(url, params, { withCredentials: true })
        .subscribe(
          (resp) => {
            observer.next(resp);
            observer.complete();
          },
          (err) => {
            console.log("Failed to activate CPQ Partner", err);
            observer.error(err);
          }
        );

      return {
        unsubscribe: () => subscription.unsubscribe(),
      };
    });
  }

  /**
   * Method to create the CPQ Account
   * @param accountFields - Object variable has the required fields to create Account.
   * @return an `Observable` for the CPQ Account Id
   */
  createAccountObject(accountFields: any): Observable<string> {
    const params = [];
    //accountFields.type = 'Account';
    params.push(accountFields);
    const url = this.cpqUrl("object", CpqObjects.Account);

    return new Observable<string>((observer) => {
      const subscription = this.http
        .post<CpqObjectUpdateResult>(url, params, { withCredentials: true })
        .subscribe(
          (resp) => {
            const accountId = resp[0]?.Id;
            if (accountId) {
              observer.next(accountId);
            } else {
              observer.error(resp[0]?.messages);
            }
            observer.complete();
          },
          (err) => {
            console.log("Failed to Create CPQ Account", err);
            observer.error(err);
          }
        );

      return {
        unsubscribe: () => subscription.unsubscribe(),
      };
    });
  }
  /**
   * sync the partner object values to Account object
   * @param partnerValues - Fields of the Partner organization
   * @param partnerId - Id of the partner organization
   */
  synchPartnerToAccount(partnerValues: any, partnerId: string): void {
    const accountFields = {
      AccountNumber: "",
      Name: partnerValues.Name,
      BillingCity: partnerValues.City,
      BillingCountry: partnerValues.Country,
      BillingPostalCode: partnerValues.PostalCode,
      BillingState: partnerValues.State,
      BillingStreet: partnerValues.Street,
      Fax: partnerValues.Fax,
      Phone: partnerValues.Phone,
      CAN_Price_List__c: partnerValues.CAN_Price_List__c,
      Customer_Type__c: partnerValues.Customer_Type__c,
      ERP_Account_Number__c: partnerValues.ERP_Account_Number__c,
      FOB__c: partnerValues.FOB__c,
      Jenks_Account_Id__c: partnerValues.Jenks_Account_Id__c,
      Payment_Terms__c: partnerValues.Payment_Terms__c,
      Pricing_Tier__c: partnerValues.Pricing_Tier__c,
      Surrey_Account_Id__c: partnerValues.Surrey_Account_Id__c,
    };

    if (partnerValues.Jenks_Account_Id__c) {
      this.createUpdateAccount(
        partnerValues.Jenks_Account_Id__c,
        partnerId,
        accountFields
      ).subscribe();
    }

    if (partnerValues.Surrey_Account_Id__c) {
      this.createUpdateAccount(
        partnerValues.Surrey_Account_Id__c,
        partnerId,
        accountFields
      ).subscribe();
    }
  }

  /**
   * Provides the read permission of any object to a Partner.
   * @param objectId - Id of the object.
   * @param partnerId - Id of the partner to get read permission.
   * @param permissions `object` containing permissions for Read, Update, and Delete
   * @returns an `Observable` of the updated object's id as a `string`
   */
  grantObjectAccessToPartner(
    objectId: string,
    partnerId: string,
    permissions: { canRead?: boolean; canUpdate?: boolean; canDelete?: boolean }
  ): Observable<string> {
    if (!objectId) {
      return throwError("A valid Object id is required");
    }
    if (!partnerId) {
      return throwError("A valid Partner id is required");
    }
    const url = this.cpqUrl(objectId, "visibility", "partner");
    const params = [];
    const payload = {
      CanRead: permissions?.canRead,
      CanUpdate: permissions?.canUpdate,
      CanDelete: permissions?.canDelete,
      id: partnerId,
    };
    params.push(payload);

    return new Observable((observer) => {
      const subscription = this.http
        .put<any>(url, params, { withCredentials: true })
        .subscribe(
          (resp) => {
            const resultPartnerId = resp[0]?.Id;
            if (resultPartnerId) {
              observer.next(resultPartnerId);
            } else {
              observer.error();
            }
            observer.complete();
          },
          (err) => {
            console.log("Failed to grant object access to partner", err);
            observer.error(err);
          }
        );

      return {
        unsubscribe: () => subscription.unsubscribe(),
      };
    });
  }

  /**
   * If No Account, Create a new Account. If Account exists, updates the account.
   * @param erpAccountNumber Account number as a `string`
   * @param partnerId Id of the partner as a `string`
   * @param cpqAccount an `object` with the Account object fields.
   * @returns an `Observable` of the resulting account object id as a `string`
   */
  createUpdateAccount(
    erpAccountNumber: string,
    partnerId: string,
    cpqAccount: any
  ): Observable<string> {
    return this.getAccountByAccountNumber(erpAccountNumber).pipe(
      switchMap((existingAccounts) => {
        cpqAccount.AccountNumber = erpAccountNumber;

        if (existingAccounts.length > 0) {
          const accountObjectFields = [];
          for (const account of existingAccounts) {
            accountObjectFields.push({ Id: account.Id, ...cpqAccount });
          }
          return this.updateObject(accountObjectFields);
        } else {
          return this.createAccountObject(cpqAccount);
        }
      }),
      mergeMap((id) =>
        this.grantObjectAccessToPartner(id, partnerId, {
          canRead: true,
          canUpdate: false,
          canDelete: false,
        })
      )
    );
  }

  validateOpportunityId(id: string): Observable<any> {
    return of(this.oppurtunityId === id ? id : this.oppurtunityId);
  }

  validateQuoteId(id: string): Observable<any> {
    return of(this.quoteId === id ? id : this.quoteId);
  }

  validateConfigId(id: string): Observable<any> {
    return of(this.configId === id ? id : this.configId);
  }

  createCorrelationId(): Observable<any> {
    return of(this.correlationId);
  }

  validateCorrelationId(id: string): Observable<any> {
    return of(this.correlationId === id ? id : this.correlationId);
  }

  /**
   * Opens a new configuration using the first (default) dataset
   * @param oppId the Opportunity Id as a `string`
   * @param quoteId the Quote Id as a `string`
   */
  openConfigurationDefaultDataset(
    oppId: string,
    quoteId: string
  ): Observable<CpqOpenConfigResult> {
    const returnObservable = new Observable<CpqOpenConfigResult>((observer) => {
      let openSubscription: Subscription;
      const familySubscription = this.fetchFamilies(quoteId).subscribe(
        (families) => {
          const datasetGuid = families[0]?.id; // FIXME: Temporary workarond while TWG_DEV is not promoted
          if (datasetGuid) {
            openSubscription = this.openConfiguration(
              datasetGuid,
              oppId,
              quoteId
            ).subscribe(
              (data) => {
                observer.next(data);
                observer.complete();
              },
              (err) => {
                // Bubble up error opening configuration
                observer.error(err);
              }
            );
          } else {
            observer.error(
              `There were no valid datasets for quote: ${quoteId}`
            );
          }
        },
        (err) => {
          // Bubble up error fetching families
          observer.error(err);
        }
      );

      return {
        unsubscribe: () => {
          familySubscription.unsubscribe();
          openSubscription.unsubscribe();
        },
      };
    });

    return returnObservable;
  }

  openConfiguration(
    familyId: string,
    oppId: string,
    quoteId: string
  ): Observable<CpqOpenConfigResult> {
    const url = this.cpqUrl("cpaas", "configs");
    const options: object = {
      params: {
        ds_guid: familyId,
        opp_id: oppId,
        quote_id: quoteId,
        run_sys_sels: true,
      },
      withCredentials: true,
    };
    return this.http.get<CpqOpenConfigResult>(url, options);
  }

  /**
   * Opens a configuration on an existing product, saves the configuration, and closes the session.
   * @param productId as `string`
   * @returns Observable of success
   */
  resaveProduct(productId: string): Observable<boolean> {
    return this.reopenConfiguration(productId).pipe(
      mergeMap((configData) => {
        return this.editProductConfiguration(
          configData.configId,
          configData.productId
        ).pipe(mergeMap((x) => this.closeConfiguration(configData.configId)));
      })
    );
  }

  reopenConfiguration(productId: string): Observable<CpqReOpenConfigResult> {
    const url = this.cpqUrl("cpaas", "configs", productId);
    const params = new HttpParams()
      .set("run_sys_sels", "true")
      .set("update", "latest"); // + '?alias=newalias');

    return new Observable((observer) => {
      const subscription = this.http
        .get<any>(url, { params, withCredentials: true })
        .subscribe(
          (resp) => {
            if (!resp?.success) {
              console.log("reopen Configuration was unsuccessful", resp);
              observer.error("reopen Configuration was unsuccessful");
            } else {
              const result: CpqReOpenConfigResult = {
                productId: resp?.productId,
                configId: resp?.configId,
                readOnly: resp?.readOnly,
                currency: resp?.currency,
              };
              // console.log('reopen Configuration was successful', result);
              observer.next(result);
              observer.complete();
            }
          },
          (err) => {
            console.log("Failed to reopen Configuration", err);
            observer.error("Failed to reopen Configuration");
          }
        );

      return {
        unsubscribe: () => subscription.unsubscribe(),
      };
    });
  }

  /**
   * Save a configuration for the first time.
   * @param configId required `String` as the id of the config session
   * @param quoteId required `String` as the id of the target quote to hold the saved config
   * @return an `Observable` for the `CpqSaveConfigResult`
   */
  saveConfiguration(
    configId: string,
    quoteId: string
  ): Observable<CpqSaveConfigResult> {
    if (!configId) {
      return throwError("A valid config id is required");
    }
    if (!quoteId) {
      return throwError("A valid quote id is required");
    }

    const url = this.cpqUrl("cpaas", "configs", configId);
    const payload = {
      quoteId,
    };

    return new Observable((observer) => {
      const subscription = this.http
        .post<any>(url, payload, { withCredentials: true })
        .subscribe(
          (resp) => {
            if (!resp?.success) {
              console.log("The save was unsuccessful", resp);
              observer.error("The save was unsuccessful");
            } else {
              const result: CpqSaveConfigResult = {
                productId: resp?.productId,
                quoteProductId: resp?.quoteProductId,
              };

              observer.next(result);
              observer.complete();
            }
          },
          (err) => {
            console.log("Failed to Save Configuration", err);
            observer.error("Failed to Save Configuration");
          }
        );

      return {
        unsubscribe: () => subscription.unsubscribe(),
      };
    });
  }

  closeConfiguration(configId: string): Observable<boolean> {
    return this.loginService.getRfst().pipe(
      switchMap((rfst) => {
        const url = this.cpqUrl("cpaas", "configs", configId);
        const params = new HttpParams().set("rfst", rfst);
        return this.http.delete(url, { params, withCredentials: true });
      }),
      map((x) => true),
      catchError((err) => {
        console.warn("Issue with saving", err);
        return of(false);
      })
    );
  }

  editProductConfiguration(
    configId: string,
    productId: string
  ): Observable<boolean> {
    return this.loginService.getRfst().pipe(
      switchMap((rfst) => {
        const url = this.cpqUrl("cpaas", "configs", configId, productId);
        const params = new HttpParams().set("rfst", rfst);
        const httpOptions = {
          params,
          headers: new HttpHeaders({
            "Content-Type": "application/x-www-form-urlencoded",
          }),
          withCredentials: true,
        };
        return this.http.put(url, null, httpOptions);
      }),
      map((x) => true),
      catchError((err) => {
        console.warn("Issue with saving", err);
        return of(false);
      })
    );
  }

  /**
   * To get the URL for QuoteSummary system proposal of format type PDF
   * @param quoteId required `String` as the id of the target quote
   */
  getSystemProposalUrl(quoteId: string) {
    // const url = this.cpqUrl('cpq', 'proposal', 'quotesummary', 'printable');
    // const proposalURL = url + '?associatedId=' + quoteId;
    const proposalURL = `${this.backendUrl}/cpq/proposal?path=/rs/19/cpq/proposal/quotesummary/printable?associatedId=${quoteId}`;
    return proposalURL;
  }

  /**
   * CDS
   * To get a Quote data for proposal generation
   * @param quoteId required `String` as the id of the quote object
   */
  getQuoteProposalData(
    quoteId: string,
    proposalName?: string
  ): Observable<CpqProposalData> {
    const url = this.cdsCpqUrl("quote", quoteId, CPQ_API.PROPOSAL);
    const params = new HttpParams().set("name", proposalName);

    return this.http.get<CpqProposalData>(url, {
      params,
      withCredentials: true,
    });
  }

  /**
   * CDS
   * To get a Config data
   */
  getConfigOptions(): Observable<any> {
    const url = this.cdsCpqUrl(CPQ_API.CONFIG, CPQ_API.OPTIONS);
    return this.http.get<any>(url, { withCredentials: true });
  }

  /**
   * To get the URL for Quote system proposal of format type PDF
   * @param proposalId required `String` as the id of the proposal object
   */
  getProposalUrl(proposalId: string) {
    // const url = this.cpqUrl('cpq', 'proposal', proposalId, 'printable');
    const url = `${this.backendUrl}/cpq/proposal?path=/rs/19/cpq/proposal/${proposalId}/printable`;
    return url;
  }

  /**
   * Starts / submits workflow for the quote.
   * @param quoteId required `string` as the id of the quote to start
   */
  startWorkflow(quoteId: any): Observable<any> {
    if (!quoteId?.trim()) {
      return throwError("Missing required quoteId");
    }

    const url = this.cpqUrl("cpqui", "workflowprocess");
    const params = new HttpParams()
      .set("action", "tr_request")
      .set("uid", quoteId);

    return this.http
      .get(url, { params, withCredentials: true })
      .pipe(switchMap((x) => this.updateQuoteData(quoteId)));
  }

  /**
   * Trigger export for a given object
   * @param id required `string` of the object's id
   */
  triggerExport(id: string): Observable<boolean> {
    const url = `${this.backendUrl}/cpq/Proxy?path=/quote/exportwf.do?id=${id}`;

    return new Observable<boolean>((observer) => {
      const sub = this.http
        .get(url, {
          observe: "body",
          responseType: "text",
          withCredentials: true,
        })
        .subscribe(
          (reply) => {
            // The reply will always be 200 and an XML. Must check result to determine if sucessful
            // Instead of completely parsing the XML this code 'cheats' and just looks for
            // the success statement '<results success="true">'

            if (reply.includes(`<results success="true">`)) {
              observer.next(true);
              observer.complete();
            } else {
              observer.next(false);
              observer.complete();
            }
          },
          (err) => {
            // FPX has CORS set incorrectly and the response will indicate failure, but it actually succeeded
            observer.next(true);
            observer.complete();
          }
        );

      return {
        unsubscribe: sub.unsubscribe,
      };
    });
  }

  /**
   * Causes FPX Quoteline Products under the provided Opportunity or Quote
   * to be updated, if possible.
   * @param ids an array of `string` for objects to be updated
   */
  updateProducts(ids: string[]): Observable<CpqProductUpdate> {
    const body = {
      ids,
    };
    const url = this.cpqUrl("cpq", "updateproducts");

    return this.http.post<CpqProductUpdate>(url, body, {
      withCredentials: true,
    });
  }

  /************ Internal Methods ********/

  cpqUrl(...args: string[]): string {
    let url = `${this.backendUrl}/cpq/Proxy?path=/rs/19`;

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < args.length; i++) {
      if (args[i] != null) {
        // Do not append null or undefined; doesn't stop empty strings
        url += "/" + args[i];
      }
    }

    return url;
  }

  cdsCpqUrl(...args: string[]): string {
    let url = `${this.backendUrl}/cpq`;

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < args.length; i++) {
      if (args[i] != null) {
        // Do not append null or undefined; doesn't stop empty strings
        url += "/" + args[i];
      }
    }

    return url;
  }

  imagePath(imagePath: string) {
    return this.cpqUrl("cpq", imagePath);
  }

  /**
   * Returns all the accounts visible to the current user
   */
  getCpqAccounts(): Observable<CpqAccount[]> {
    const url = this.cpqUrl("object", "accounts");
    return this.http.get<CpqAccount[]>(url, { withCredentials: true });
  }
  /**
   * return the external account information of the opportunity
   * @param oppurtunityId string of opportunity id
   */
  getExternalAccountFromOpp(
    oppurtunityId: string
  ): Observable<OppAccountResult[]> {
    const url = `${this.backendUrl}/cpq/getExternalAccountFromOpp`;
    const params = new HttpParams().set("oppId", oppurtunityId);
    return this.http.get<OppAccountResult[]>(url, {
      params,
      withCredentials: true,
    });
  }

  /**
   * Queries for the Account object id for a given Account Number
   * @param accNumber `string` of the Account Number
   * @returns `Observable` of account id as `string`
   */
  getAccountByAccountNumber(accNumber: string): Observable<any[]> {
    if (!accNumber) {
      return throwError("A valid Account Number is required");
    }
    const url = `${this.backendUrl}/cpq/getAccountByAccountNumber`;
    const params = new HttpParams().set("account", accNumber);

    return new Observable<any>((observer) => {
      const subscription = this.http
        .get(url, { params, withCredentials: true })
        .subscribe(
          (results) => {
            observer.next(results);
            observer.complete();
          },
          (err) => {
            console.log("Failed to fetch the AccountId", err);
            observer.error("Failed to fetch the AccountId");
          }
        );

      return {
        unsubscribe: () => subscription.unsubscribe(),
      };
    });
  }

  /**
   * Function to log the actions with its related information
   * @param actionInfo - Object provides the actions and its informations
   * @returns
   */
  logActions(actionInfo: action): Observable<string> {
    const url = this.cpqUrl("actionlogs");
    return this.http.post<string>(url, actionInfo, { withCredentials: true });
  }

  /**
   * Provide a specific product information on a quoteline
   * @param quoteLineId - Id of the quoteline
   * @param productId - Id of the quoteline`s product
   * @returns as the Quoteline`s product information
   */
  getQuoteLineProductById<T>(
    quoteLineId: string,
    productId: string
  ): Observable<T> {
    if (!quoteLineId || !productId) {
      return throwError(
        "A valid ID is required to get the QuoteLine`s Product details"
      );
    }

    const url = this.cpqUrl("quoteline", quoteLineId, "product", productId);

    return new Observable((observer) => {
      const subscription = this.http
        .get<any>(url, { withCredentials: true })
        .subscribe(
          (resp) => {
            if (resp?.Product) {
              observer.next(resp);
            } else {
              observer.error(resp?.messages);
            }
            observer.complete();
          },
          (err) => {
            console.log("Failed to get the quoteline`s Product details", err);
            observer.error(err);
          }
        );

      return {
        unsubscribe: () => subscription.unsubscribe(),
      };
    });
  }

  /**
   * get product list
   * @param keyword - searched keyword
   * @returns as the product list
   */
  getProducts<T>(keyword?: string): Observable<T[]> {
    const url = this.cdsCpqUrl("products");
    const params = new HttpParams().set("keyword", keyword);

    return new Observable((observer) => {
      const subscription = this.http
        .get<any>(url, { params, withCredentials: true })
        .subscribe(
          (resp) => {
            if (resp) {
              observer.next(resp);
            } else {
              observer.error(resp?.messages);
            }
            observer.complete();
          },
          (err) => {
            console.log("Failed to get the Product details", err);
            observer.error(err);
          }
        );

      return {
        unsubscribe: () => subscription.unsubscribe(),
      };
    });
  }

  getImageAsBase64(url: string): Observable<string> {
    return new Observable<string>((observer) => {
      this.http.get(url, { responseType: "blob" }).subscribe(
        (imageBlob: Blob) => {
          const reader = new FileReader();

          reader.onloadend = () => {
            if (reader.result) {
              const imageBase64 = reader.result as string;
              observer.next(imageBase64);
              observer.complete();
            } else {
              observer.error("Failed to convert image to base64.");
            }
          };

          reader.readAsDataURL(imageBlob);
        },
        (error) => {
          observer.error("Failed to fetch image: " + error);
        }
      );
    });
  }
}
