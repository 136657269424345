import { Injectable } from '@angular/core';
import { LoginService } from '@cpq-app/services/login.service';
import { environment } from '@cpq-environments/environment';
import { MSALService } from './msal.service';

@Injectable({
  providedIn: 'root',
})
export class LogoutService {
  constructor(
    private msalService: MSALService,
    private loginService: LoginService,
  ) { }

  logout() {
    this.loginService.logout().subscribe(
      res => {
        this.msalService.logout();
        window.location.href = `${environment.B2CConfigs.cxPortal.baseUrl}/logout`;
      },
      err => {
        this.msalService.logout();
      });
  }

  logoutRedirectFromCXPortal() {
    this.msalService.logout();
    // window.location.href = environment.B2CConfigs.cxPortal.baseUrl;
    window.location.href = `${environment.B2CConfigs.cxPortal.baseUrl}/logout`;
  }
}
