import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { MSALService } from '@cpq-app/shared/services/msal.service';
import { AlertService } from '@cpq-app/shared/services/alert.service';
import { AppConfig } from '@cpq-app/config/app.config';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsersService } from '@cpq-app/adminstration/users/users.service';
import { CartService, CpqObjects } from '@cpq-app/services/cart.service';
import { forkJoin, of } from 'rxjs';
import { HeaderService } from './header.service';
import { GuestConfigService } from '@cpq-app/adminstration/guest-config/guest-config.service';
import { LogoutService } from '@cpq-app/shared/services/logoutService';
import { StatusCodes } from 'http-status-codes';
import { environment } from '@cpq-environments/environment';
export enum SessionVariables {
  PERSONA = 'persona',
  USER_TYPE = 'userType',
  OPCO_NAME = 'opcoName'
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  headerData = {};
  userName;
 userRole ?: String;
  private backendUrl = environment.B2CConfigs.BackendURL;
  userId: any;
  constructor(
    private router: Router,
    private http: HttpClient,
    private appConfig: AppConfig,
    private msalService: MSALService,
    private userService: UsersService,
    private headerService: HeaderService,
    private alertService: AlertService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private cartService: CartService,
    private guestService: GuestConfigService,
    private logoutService: LogoutService
  ) { }

  ngOnInit() {
    if(!sessionStorage.getItem('salesforceToken')) {
      this.getRole();
      // this.getHeaderDetails(this.userRole);
    }
  }

  cpqUrl(...args: string[]): string {
    let url = `${this.backendUrl}/cpq`;

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < args.length; i++) {
        if (args[i] != null) {
            // Do not append null or undefined; doesn't stop empty strings
            url += '/' + args[i];
        }
    }

    return url;
}

  getRole()
  {
    const url = this.cpqUrl('session');
    const getSub = this.http
    .get<any>(url)
    .subscribe(response => {
        //console.log(`%cValidate:%c Status is ${response.ok}`, 'background-color: green', 'background-color:white');
        // If the response is OK, then the session is valid
        this.userRole =  response.userRole;
        this.userId = response.id;
        this.getHeaderDetails(this.userRole);
    }, error => {
        console.log(`%cValidate:%c Status is errored`, 'background-color: red', 'background-color:white');
    });
    // this.userRole = sessionStorage.getItem('userRole');
  }


  getHeaderDetails(userRole) {
    this.spinner.show();
    const Id = this.userId;
    // if(userRole === 'External'){

    // }
    this.userService.getUserById(Id).subscribe(res => {
      //if user is not returned
      if (!Id) {
        this.toaster.error('User is not registered within application');
        this.logoutService.logout();
      } 
      //check if user is inactive
      if(!res['IsActive']){
        this.toaster.warning('User is inactive. Please contact admin in order to login.')
        this.logoutService.logout();
      }
      else {
        let userData = res;
        let headerData;
        this.headerService.getHeader(userRole).subscribe(res1 => {
          if (res1['statusCode'] !== StatusCodes.OK) {
            let userName = userData['FirstName'] + ' ' + userData['LastName'];
            this.headerService.setUsername(userName);
            headerData = res1['data'];
            headerData['user'] = {};
            headerData['user']['role'] = userRole;
            headerData['user']['userType']= userRole;
            headerData['user']['userName'] = userName;
            sessionStorage.setItem('userName', userData['userName']);
            this.headerData = headerData;
            sessionStorage.setItem('userType', headerData['user']['userType']);
            // this.alertService.error(`${res1['message']}`);
          } else {
              headerData = res1['data'];
              // this.guestService.setGuestUserDetails(userData);
              let userRole = '';
              headerData['user'] = {};
            
              if (userData === null) {
                userRole = 'Super Admin';
                let userName = userData['FirstName'] + ' ' + userData['LastName'];
                headerData['user']['userName'] = userName;
                this.headerService.setUsername(userName);
                headerData['user']['userType'] = 'superadmin';
              } else {
                userRole = userData['personaName'];
                headerData['user']['userType'] = userData['userType'];
                let userName = userData['FirstName'] + ' ' + userData['LastName'];
                headerData['user']['userName'] = userName;
                this.headerService.setUsername(userName);
                sessionStorage.setItem('privacyTerms', userData['privacyTerm']?.isAccepted);
                sessionStorage.setItem('userName', userData['userName']);
                if (userData['privacyTerm']?.isAccepted === false || userData['privacyTerm']?.isAccepted === null ) {
                  this.router.navigate(['/privacy-terms']);
                }
                // if (userData.isActive !== true) {
                //   if(userData.isDeactivatedBySystem === 'true' && userData.isActive !== true && userData.userType !== 'Guest') {
                //     userData.isActive = true;
                //     userData.isDeactivatedBySystem = false;
                //     userData.lastLoggedIn = new Date().toISOString();
                //     this.activateUser(userData.oId);
                //   } else {
                //     this.toaster.warning('User is inactive. Please contact admin in order to login.')
                //     this.logoutService.logout();
                //   }
                // }
                // this.updateLastLoginData(userData);
              }
              // this.headerService.getUserName().subscribe((name) => {
              //   this.userName = name;
              // })
              sessionStorage.setItem('persona', userRole);
              headerData['user']['role'] = userRole;
              // headerData['user']['pic'] =
              //   userRole === 'Super Admin'
              //     ? './assets/images/administrator-profile-pic.png'
              //     : userData?.profilePic?.blobUri;
            this.headerData = headerData;
            sessionStorage.setItem('userType', headerData['user']['userType']);
            }
            // this.updateLastLoginData(userData);
          });
        }
        this.spinner.hide();
    });
  }

  updateLastLoginData(userData) {
    userData.lastLoggedIn = new Date().toISOString();
    this.cartService.updateObjectById(CpqObjects.Users,this.userId,userData,).subscribe((res) => {
    }, err => {
       this.toaster.error(err.message);
    });
  }

  activateUser(oId) {
    this.userService.activateUser(oId).subscribe((res) => {
      window.location.reload();
    }, err => {
        this.toaster.error(err.message);
    });
  }
}
