import { Component, OnInit } from '@angular/core';
import { UsersService } from '@cpq-app/adminstration/users/users.service';
import { MSALService } from '@cpq-app/shared/services/msal.service';
import { AlertService } from '@cpq-app/shared/services/alert.service';
import { AppConfig } from '@cpq-app/config/app.config';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { LogoutService } from '@cpq-app/shared/services/logoutService';

@Component({
  selector: 'app-privacy-terms',
  templateUrl: './privacy-terms.component.html',
  styleUrls: ['./privacy-terms.component.scss']
})
export class PrivacyTermsComponent implements OnInit {

  isPrivacyTerms;
  userId;

  constructor(public userService: UsersService,
    private router: Router,
    private msalService: MSALService,
    private alertService: AlertService,
    private toaster: ToastrService,
    private logoutService: LogoutService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    const loggedInUserAdInfo = this.msalService.getUser().idToken;
    this.userService.getUser(loggedInUserAdInfo.oid).subscribe((res) => {
      if ( res['statusCode'] !== 200) {
        this.alertService.error(
          `${res['message']}`
        );
      } else {
        const userData = res['data'];
        if (
          userData === null ||
          typeof userData === typeof undefined ||
          typeof userData['persona'] === typeof undefined
        ) {
          this.userId = '';
        } else {
          this.userId = userData['Id'];
          this.isPrivacyTerms = true;
        }
      }
    });
  }

  accept() {
    this.spinner.show();
    let data = { id: this.userId,privacyStatus: this.isPrivacyTerms };
    sessionStorage.setItem('privacyTerms', this.isPrivacyTerms);
    if(this.isPrivacyTerms){
      this.toaster.info('privacy terms updated successfully.');
      this.router.navigate(['/']);
    }
  }

  decline() {
    this.logoutService.logout();
  }

}
