<div *ngIf="displayedColumns">

    <!-- <div class="custom-search" *ngIf="!hideSearch">
        <mat-form-field appearance="fill">
            <mat-label>Search</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="{{searchText}}" #input>
        </mat-form-field>
    </div> -->

    <div class="cpq-page-sub-header textcapitalize margin-top_30 mb-3">Quotes</div>

    <div class="mat-elevation-z8 cpq-data-table" [ngClass]=" !hidePagination ? 'pb-2' : ''">
        <!-- matSort (matSortChange)="sortData($event)" -->
        <!-- mat-sort-header="{{column.field}}" -->
        <table class="mb-2" mat-table [dataSource]="dataSource">
            <ng-container [matColumnDef]="column.header" *ngFor="let column of colDefinition; let i=index">
                <th mat-header-cell *matHeaderCellDef> {{column.header}} </th>
                <td mat-cell *matCellDef="let element; let rowIndex=index">
                    <div [ngSwitch]="column.fieldType">
                        <div *ngSwitchCase="'input'">
                            <input *ngIf="column.value(element) != undefined" [ngModel]="column.value(element)"
                                (change)="onInputChange(column.mappingKey(element), $event)">
                        </div>
                        <div *ngSwitchCase="'textArea'">
                            <textarea class="w-100" readonly matInput type="text" [ngModel]="column.value(element)"
                                rows="1"></textarea>
                        </div>
                        <div class="row ml-2 mr-1" *ngSwitchCase="'actions'">
                            <div class="icon" *ngFor="let option of column.value(element);" title="{{option.name}}"
                                [id]="option.name+rowIndex+column.field"
                                [ngClass]="{'disabled': (option.name === 'edit' || option.name === 'delete' || option.name === 'copy') && disableActionButtons}">
                                <!-- option.action === 'delete'? openConfirmationDialog(option) : -->
                                <!-- {{option}} {{rowIndex}} -->
                                <div class="pointer">
                                    <mat-icon *ngIf="option.name === 'copy'" [ngClass]="{'disabled': option.disable}"
                                        (click)="actionClick('copy', element)">content_copy</mat-icon>
                                    <mat-icon *ngIf="option.name === 'view'" [ngClass]="{'disabled': option.disable}"
                                        (click)="actionClick('view', element)">remove_red_eye</mat-icon>
                                    <mat-icon *ngIf="option.name === 'FileView'"
                                        [ngClass]="{'disabled': option.disable}"
                                        (click)="actionClick('FileView', element)">insert_drive_file</mat-icon>
                                    <mat-icon *ngIf="option.name === 'delete'" [ngClass]="{'disabled': option.disable}"
                                        (click)="actionClick('delete', element)">delete</mat-icon>
                                    <mat-icon *ngIf="option.name === 'download'"
                                        [ngClass]="{'disabled': option.disable}"
                                        (click)="actionClick('download', element)">download</mat-icon>
                                    <mat-icon *ngIf="option.name === 'edit'" [ngClass]="{'disabled': option.disable}"
                                        (click)="actionClick('edit', element)">edit</mat-icon>
                                    <mat-icon [ngClass]="{'disabled': option.disable}"
                                        *ngIf="option.name === 'reconfig' && option.visibility">settings</mat-icon>
                                </div>
                            </div>
                        </div>
                        <div *ngSwitchCase="'radio'">
                            <mat-radio-button [id]="column.field + '-' + rowIndex" [name]="column.field"
                                [checked]="column.value(element)" [disabled]="disableActionButtons"
                                (click)="onRadioClick(column, element)"></mat-radio-button>
                        </div>
                        <div *ngSwitchDefault>
                            <div *ngIf="column.field!=='actions'">
                                <span class="text-truncate" [title]="column.value(element)"
                                    [ngClass]="column.hasOwnProperty('className') ? column.className(element): ''">
                                    {{column.value(element)}}
                                </span>
                                <!-- <ng-template #elseHandlerBlock>
                                    <span class="text-truncate" [title]="column.value(element)">
                                        {{column.value(element)}}
                                    </span>
                                </ng-template> -->
                            </div>
                        </div>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell p-3 text-center" [attr.colspan]="displayedColumns.length">
                    No records found.
                </td>
            </tr>
        </table>
        <mat-paginator #paginator *ngIf="!hidePagination" [pageSizeOptions]="[5, 10, 15]" aria-label="Select page">
        </mat-paginator>
    </div>

    <!-- <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator> -->

</div>
<ng-template #ConfirmDialog>
    <div mat-dialog-content>
        <p>Are you sure that you want to proceed?</p>
    </div>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>No</button>
        <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Yes</button>
    </mat-dialog-actions>
</ng-template>