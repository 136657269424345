import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GuestConfigService } from '@cpq-app/adminstration/guest-config/guest-config.service';
import { CartService, CpqObjects } from '@cpq-app/services/cart.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { OpenOpportunityComponent } from '../open-opportunity/open-opportunity.component';
const DATE_FMT = 'yyyy-MM-dd';
@Component({
  selector: 'app-opportunity',
  templateUrl: './opportunity.component.html',
  styleUrls: ['./opportunity.component.scss']
})
export class OpportunityComponent {
  @Input() opportunity;
  opportunityForm: FormGroup;
  canEditProject: boolean;
  submitted = false;
  subscription$: Subscription[] = [];
  opportunityName: string;
  hasRevisionRecords: boolean;
  displayEditProject = false;
  opportunities: any[] = [];
  PROJECT_SPINNER = 'project spinner'
  accountName: string;
  isInternalUser: boolean;
  show = false
  @Input() quoteSubmittedStatus: string;
  opportunityId;
  @Output() onUpdate? = new EventEmitter();

  constructor(
    private cartService: CartService,
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe,
    private modalService: NgbModal,
    private guestService: GuestConfigService,
    private router: Router,
    private toastr: ToastrService,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {

    ///this.initialFormData();
    this.opportunityId = this.opportunity?.Id;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.opportunity?.currentValue) {
      this.initialFormData(changes?.opportunity?.currentValue);
      this.opportunityId = this.opportunity.Id;
    }
  }

  setData(opportunity) {
    this.opportunityForm.controls.Name.setValue(opportunity?.Name);
    this.opportunityForm.controls.Owner.setValue(opportunity?.Owner?.Name);
    this.opportunityForm.controls.ModifiedTime.setValue(this.datePipe.transform(opportunity?.ModifiedTime, DATE_FMT));
    this.opportunityForm.controls.CreatedDate.setValue(this.datePipe.transform(opportunity?.CreatedTime, DATE_FMT));
    this.accountName = opportunity?.Account?.Name;
    this.opportunities = opportunity;
    this.show = true;
  }

  initialFormData(opportunities) {
    const nonWhiteSpaceRegExp: RegExp = new RegExp('\\S');
    const zipcodePattern = '^[a-zA-Z0-9_-\\s]{5,10}$';
    this.opportunityForm = this.formBuilder.group({
      Name: [null, [Validators.required, Validators.pattern(nonWhiteSpaceRegExp), Validators.maxLength(120)]],
      Owner: [{ value: null, disabled: true }],
      ModifiedTime: [{ value: null, disabled: true }],
      CreatedDate: [{ value: null, disabled: true }]
    });
    if (opportunities) {
      this.setData(opportunities)
    }
  }

  editFormInitialData() {
    this.subscription$.push(this.cartService.getCpqObjects(CpqObjects.Opportunity, this.opportunityId)
      .subscribe({
        next: (opportunity: any) => {
          this.opportunity = opportunity;
          console.log('opportunity', opportunity);
          this.opportunityForm.controls.Name.setValue(opportunity?.Name);
          this.opportunityForm.controls.Owner.setValue(opportunity?.Owner?.Name);
          this.opportunityForm.controls.ModifiedTime.setValue(this.datePipe.transform(opportunity?.ModifiedTime, DATE_FMT));
          this.opportunityForm.controls.CreatedDate.setValue(this.datePipe.transform(opportunity?.CreatedTime, DATE_FMT));
          this.accountName = opportunity?.Account?.Name;
          this.onUpdate.emit(opportunity);
        },
        error: err => {
          this.toastr.error(
            'There is fatal error while fetching revision', 'Error', {
            disableTimeOut: true,
            closeButton: true
          }
          );
        }
      }));
  }

  getControlValue(type: string) {
    return this.opportunityForm.controls[type].value;
  }



  editProject() {
    this.displayEditProject = !this.displayEditProject;
  }

  cancelEditProject() {
    if (this.opportunityForm.controls['Name'].dirty) {
      this.opportunityForm.controls['Name'].setValue(this.opportunity.Name);
    }
    this.displayEditProject = !this.displayEditProject;
  }

  getProjectName() {
    return this.opportunityForm.controls.Name.value;
  }



  openProject() {
    const instance = this.modalService.open(OpenOpportunityComponent, {
      size: 'xl'
    });
    instance.componentInstance.opportunity = JSON.stringify(this.opportunity);
    instance.result.then((outcome) => { this.editFormInitialData(); }, (dismiss) => {
      this.editFormInitialData();
    });

  }

  ngOnDestroy() {
    this.subscription$.forEach(sub => sub.unsubscribe());
  }
}
