<div class="project-details mt-2">
    <div *ngIf = "show">
        <form [formGroup]="opportunityForm">
            <div class="d-flex flex-row flex-wrap justify-content-between">
                <div class="subtextopp">Opportunity Details</div>
                <div [ngClass]="{'disabled': quoteSubmittedStatus}">
                    <i (click)="openProject()" class="fa fa-pencil cursor mr-2" aria-hidden="true" title="edit opportunity details"></i>
                </div>
            </div>

            <div class="boxopp">
                <div class="parent d-flex flex-wrap">
                    <div class="child">
                        <div for="Name" class="form-labels">Opportunity Name</div>
                        <div class="label-value">{{getControlValue('Name')}}</div>
                    </div>
                    <div class="child">
                        <div for="CreatedDate" class="form-labels">Creation Date</div>
                        <div class="label-value">{{getControlValue('CreatedDate')}}</div>
                    </div>
                    <div class="child">
                        <div for="ModifiedTime" class="form-labels">Last Updated</div>
                        <div class="label-value">{{getControlValue('ModifiedTime')}}</div>
                    </div>
                </div>
                <div class="parent d-flex flex-wrap">
                    <div class="child">
                        <div for="Owner" class="form-labels">Opportunity Owner</div>
                        <div class="label-value">{{getControlValue('Owner')}}</div>
                    </div>
                    <div class="child">
                        <div for="" class="form-labels-value-org">Account Name</div>
                        <div class="label-value-org-value">{{accountName?accountName:'NA'}}</div>
                    </div>
                    <div class="child">
                        <div for="" class="form-labels-value-org"></div>
                        <div class="label-value-org-value"></div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>