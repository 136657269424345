<div>
  <div fxLayout="row" fxLayoutAlign="end end">
    <button mat-button class="close-icon" (click)="done()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<h1 fxLayout="row" class="cpq-page-header textcapitalize">Thank You</h1>
<div fxLayout="column" fxLayoutAlign="start start" fxLayout.lt-md="column" mat-dialog-content
  class="submit-quote-container">
  <div class="message">Our sales team will review your request and respond to you in 1 business day.</div>
  <div class="" fxFlexAlign="center"><button mat-flat-button color="accent" class="textcapitalize cpq-button-text"
      (click)="done()">DONE</button>
  </div>
</div>