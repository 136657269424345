<div class="modal-header mt-2">
    <h4 class="modal-title main-header pull-left"> Edit Opportunity </h4>
    <h4 class="small-header  pull-left">(Enter details to edit opportunity)</h4>


    <button type="button" class="close pull-right" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="project-details modal-body">
    <div class="row">
        <ngx-spinner [fullScreen]="false" type="cog" size="large" [name]="LOGIN_SPINNER">
            <p class="login-spinner">Getting things ready...</p>
          </ngx-spinner>
        <div class="col-md-12">
            <form class="pt-3 mr-3 ml-3" [formGroup]="saveProjectForm" (ngSubmit)="onProjectSave()">                
                <div class=" child-control">
                    <label for="Name" class="form-labels f-label">Project Name<span
                            class="required-text">(Required)</span></label>
                    <input type="text" class="form-control" id="Name" formControlName="Name"
                        [ngClass]="{ 'is-invalid':  (saveProjectForm.controls['Name'].dirty || saveProjectForm.controls['Name'].touched || submitted) && saveProjectForm.controls['Name'].errors }">
                    <div *ngIf="saveProjectForm.controls['Name'].invalid && (saveProjectForm.controls['Name'].dirty || saveProjectForm.controls['Name'].touched || submitted)"
                        class="invalid-feedback">
                        <div *ngIf="saveProjectForm.controls['Name'].errors?.required">
                            Project Name is required</div>
                        <div *ngIf="saveProjectForm.controls['Name'].errors?.pattern">
                            Project Name must be a character</div>
                        <div *ngIf="saveProjectForm.controls['Name'].errors?.maxlength">Project Name
                            must
                            contain max of
                            120
                            characters</div>
                    </div>
                </div>
                <div class="required child-control mt-2">
                    <label for="accountSelection" class="form-labels f-label">Accounts
                        <!-- <span class="required-text">(Required)</span> -->
                    </label>
                    <mat-form-field class="example-full-width w-100 text-left" appearance="outline">
                        <mat-select [compareWith]="compareObjects" [(ngModel)]="selectedAccount" formControlName="accountSelection">
                            <mat-option *ngFor="let account of availableAccounts" [value]="account" class="account-text">
                                {{account.Name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-error
                        *ngIf="saveProjectForm.controls['accountSelection'].invalid && (saveProjectForm.controls['accountSelection'].dirty || saveProjectForm.controls['accountSelection'].touched || submitted)"
                        class="invalid-feedback">
                        <div *ngIf="saveProjectForm.controls['accountSelection'].errors?.required">Account Name is
                            required
                        </div>
                        <div *ngIf="saveProjectForm.controls['accountSelection'].errors.pattern">
                            Account Name must be a character</div>
                        <div *ngIf="saveProjectForm.controls['accountSelection'].errors.maxlength">Account Name must
                            contain
                            max
                            of
                            100 characters</div>
                    </mat-error>
                </div>                
                <div class="modal-footer d-flex">
                    <button  mat-flat-button color="accent" type="button" class="cpq-button-text btn-submit-quote" (click)="modal.dismiss()">Cancel</button>
                    <span *ngIf="isUpdateProject"><button  mat-flat-button color="accent" type="button" class="cpq-button-text btn-submit-quote"
                            [disabled]="saveProjectForm.invalid" (click)="onProjectSave()">Update</button></span>
                </div>
            </form>
        </div>
    </div>
</div>