import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MSALService } from '@cpq-app/shared/services/msal.service';
import { environment } from '@cpq-environments/environment';

export interface User {
  oId: string;
  firstName: string;
  lastName: string;
  userName: string;
  passwordCreated: boolean;
  password?: string;
  email: string;
  organizationId: string;
  organization?: [];
  userType: string;
  title: string;
  phone: string;
  isActive: boolean;
  address1: string;
  address2: string;
  zipcode: string;
  city: string;
  stateId: number;
  countryId: number;
  ownEmail: string;
  ownPhoneNumber: string;
  ownAlternateNumber: string;
  organizationType: string;
  userGroup: string;
  emergencyContacts: any[];
  persona: string;
  personaName: string;
  profilePic: [];
  fpxUserId: string;
  isDistributor: boolean;
  isFpxUser: boolean;
  profileId: string;
  userRoleId: string;
  maxUsers: number;
  isAdmin: boolean;
  preAuth: boolean;
  accountNumber?: string;
  fax: string;
  privacyTerm: PrivacyTerm;
  cpqAccess: boolean;
  opcoName: string;
  cpqUserName: string;
  createdBy?: string;
  createdDate: Date;
  EditedBy?: string;
  editedDate: Date;
  Id: string;
}
export interface PrivacyTerm {
  isAccepted: boolean;
  acceptedDate: Date;
}
export interface PortalData {
  statusCode: number;
  message: string;
  data: User;
}

@Injectable(
  {providedIn:'root'}
)
export class UsersService {
  constructor(private http: HttpClient, private authService: MSALService) {}

  private backendUrl = environment.B2CConfigs.BackendURL;
  private cxbackendUrl = environment.B2CConfigs.cxPortal.baseUrl;

  getData = () => {
    return this.http.get(`${this.backendUrl}/users/all`);
  };

  getUserByFilter = (pager) => {
    const customHttpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(`${this.backendUrl}/users/filter`, pager, {
      headers: customHttpHeader,
    });
  };

  getAllUsersBySearchFilter = (pager) => {
    const customHttpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(`${this.backendUrl}/users/filter/custom`, pager, {
      headers: customHttpHeader,
    });
  };

  getAllActiveUsers = () => {
    return this.http.get(`${this.backendUrl}/users/active`);
  };

  createUserData = (formdata) => {
    const customHttpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(`${this.backendUrl}/users/createUser`, formdata, {
      headers: customHttpHeader,
    });
  };

  getUserById = (id: any) => {
    return this.http.get(`${this.backendUrl}/users/getUserById/${id}`);
  };

  deleteUser = (id: any) => {
    const customHttpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.delete(`${this.backendUrl}/users/deleteUserById/${id}`, {
      headers: customHttpHeader,
    });
  };

  EditUser = (formdata, id: any) => {
    const customHttpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.put(
      `${this.backendUrl}/users/updateUserById/${id}`,
      formdata,
      { headers: customHttpHeader }
    );
  };

  EditProfile = (userObj) => {
    const customHttpHeader = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.put(
      `${this.backendUrl}/users/profile`,
      userObj,
      { headers: customHttpHeader }
    );
  };

  activateUser = (oId) => {
    let data = {'isActive': true};
    const customHttpHeader = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.put(
      `${this.backendUrl}/users/updateUserByOId/${oId}`,
      data,
      { headers: customHttpHeader }
    );
  };

  createUserInAD = (data) => {
    const aud = this.authService.getAudience();
    const customHttpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      aud: aud,
    });
    return this.http.post(`${this.backendUrl}/b2c/createuser`, data, {
      headers: customHttpHeader,
    });
  };

  checkUserExits = (userName: any) => {
    const aud = this.authService.getAudience();
    const customHttpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      aud: aud,
    });
    return this.http.get(`${this.backendUrl}/b2c/checkuser/${userName}`, {
      headers: customHttpHeader,
    });
  };

  checkAccess = (userName: any)   => {
    const aud = this.authService.getAudience();
    const customHttpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      aud: aud,
    });
    return this.http.get(
      `${this.backendUrl}/b2c/checkaccess/Platform/${userName}`,
      { headers: customHttpHeader }
    );
  };

  addapplication = (data) => {
    const aud = this.authService.getAudience();
    const customHttpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      aud: aud,
    });
    return this.http.post(`${this.backendUrl}/b2c/addapplication`, data, {
      headers: customHttpHeader,
    });
  };

  deleteUserInAD = (data) => {
    const aud = this.authService.getAudience();
    const customHttpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      aud: aud,
    });
    return this.http.post(`${this.backendUrl}/b2c/deleteuser`, data, {
      headers: customHttpHeader,
    });
  };

  getUser = (oid) => {
    const customHttpHeader = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        'Pragma': 'no-cache',
        'Expires': '0'
      })
    };
    return this.http.get(`${this.backendUrl}/users/oid/${oid}`, customHttpHeader);
  }

  getGuestUser = (oid) => {
    const customHttpHeader = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        'Pragma': 'no-cache',
        'Expires': '0'
      })
    };
    return this.http.get(`${this.backendUrl}/users/guestUser/oid/${oid}`, customHttpHeader);
  }

  getUserByUsername = (userName: any) => {
    const customHttpHeader = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.get(`${this.backendUrl}/users/getUserFromCX/${userName}`, {
      headers: customHttpHeader,
    });
  };

  getUserByUsernameFromUI = (userName: any) => {
    const customHttpHeader = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.get(`${this.cxbackendUrl}/api/users/twg/get-userby-username/${userName}`, {
      headers: customHttpHeader,
    });
  };

  getCXPortalToken = () => {
    const customHttpHeader = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.get(`${this.backendUrl}/users/getCXPortalToken`, {
      headers: customHttpHeader,
    });
  }

  updateCpqUsernameInB2C = (username: string, cpqUsername: string) => {
    const customHttpHeader = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.get(`${this.cxbackendUrl}/api/users/updateCPQUserName/twg/${username}/cpqUserName/${cpqUsername}`, {
      headers: customHttpHeader,
    });
  }

  updatePrivacyTerms = (data) => {
    const customHttpHeader = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.put(
      `${this.backendUrl}/users/updatePrivacyStatus`,
      data,
      { headers: customHttpHeader }
    );
  }
}
