import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

@Component({
  selector: 'data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataTableComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() dataSource: MatTableDataSource<any>;
  @Input() colDefinition;
  @Input() searchText;
  displayedColumns = [];
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  pageSize = 5;
  @ViewChild("ConfirmDialog") ConfirmDialog: TemplateRef<any>;
  dialogRef;
  @Input() hideSearch?;
  @Input() hidePagination? = false;
  @Output() onValueChange? = new EventEmitter();
  @Output() onRadioSelection? = new EventEmitter();
  @Output() onActionClick? = new EventEmitter();
  @Input() disableActionButtons: boolean;

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
    if (this.colDefinition) {
      this.displayedColumns = this.colDefinition.map((ele) => ele.header);
    }
  }

  ngAfterViewInit() {
    this.loadData();
  }

  ngOnChanges() {
    if (this.colDefinition) {
      this.displayedColumns = this.colDefinition.map((ele) => ele.header);
    }
    this.loadData();
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator;
      //  this.dataSource.sort = this.sort;
    }
  }

  onRadioClick(column, record) {
    const obj = {
      field: column.field,
      item: record
    }
    this.onRadioSelection.emit(obj)
  }

  sortData(sortState: Sort) {
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    // this.sort.sortChange.emit(sortState);
  }

  loadData() {
    // set default sort
    // const sortState: Sort = { active: 'LastModifiedDate', direction: 'desc' };
    // this.sort.sortChange.emit(sortState);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.dataSource.filterPredicate = (data, filterValue: string) => {
      const transformedFilter = filterValue.trim().toLowerCase();

      const listAsFlatString = (obj): string => {
        let returnVal = '';

        Object.values(obj).forEach((val) => {
          if (typeof val !== 'object') {
            returnVal = returnVal + ' ' + val;
          } else if (val !== null) {
            returnVal = returnVal + ' ' + listAsFlatString(val);
          }
        });

        return returnVal.trim().toLowerCase();
      };

      return listAsFlatString(data).includes(transformedFilter);
    };
  }

  openConfirmationDialog(option): void {
    this.dialogRef = this.dialog.open(this.ConfirmDialog, {
      width: "400px",
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        option.handler();
      }
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  onInputChange(field, event) {
    const obj = {
      variableName: field,
      value: Number(event.target.value)
    }
    this.onValueChange.emit(obj)
  }

  actionClick(eventName, eventData) {
    const event = {
      eventName: eventName,
      eventData: eventData
    }
    this.onActionClick.emit(event);
  }
}
