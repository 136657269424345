<data-table [dataSource]="dataSource" [colDefinition]="colDefinition" [hidePagination]="false" [disableActionButtons]="quoteSubmittedStatus"
    (onRadioSelection)="onRadioSelection($event)" (onActionClick) = "onActionClick($event)"></data-table>


<ng-template #ConfirmDialog>
    <div mat-dialog-content align="center">
        <p class="message">Are you sure that you want to proceed?</p>
    </div>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>NO</button>
        <button mat-flat-button color="accent" class="textcapitalize cpq-button-text" [mat-dialog-close]="true"
            cdkFocusInitial>YES</button>
    </mat-dialog-actions>
</ng-template>

<ng-template #InformationDialog>
    <div class="mb-2" mat-dialog-content align="center">
        <p class="message">Primary quote can not be deleted.</p>
    </div>
    <mat-dialog-actions class="pb-1" align="center">
        <button mat-flat-button color="accent" class="textcapitalize cpq-button-text" (click)="cancel()">CANCEL</button>
    </mat-dialog-actions>
</ng-template>

