<ngx-spinner [fullScreen]="false" type="cog" size="large" [name]="LOGIN_SPINNER">
  <p class="login-spinner">Getting things ready...</p>
</ngx-spinner>
<!--top link section-->
<app-breadcrumbs>
  <div>
    <a (click)="navigateToHome()">HOME</a>
  </div>
</app-breadcrumbs>
<!--top Header-->
<h1 class="textcapitalize cpq-page-header">Configure Home</h1>
<app-opportunity  *ngIf = "internalUser" [opportunity] = "opportunity" [quoteSubmittedStatus]="quoteSubmittedStatus" (onUpdate) = "onUpdate($event)"></app-opportunity>
<!--top configuration section-->
<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
  <div fxLayout="column" class="landing-rectangle-large" fxFlex="40" fxLayoutAlign="space-between start">
    <div class="landing-page-box-text">{{internalUser ?  'Add products to selected quote' : 'Start a New Configuration'}}</div>
    <div class="box-description-text" fxFlex="grow">Answer a few questions to have products recommended to you.
    </div>   
        <div><button mat-flat-button color="accent" class="textcapitalize cpq-button-text" [disabled]="quoteSubmittedStatus"
          (click)="startNewConfiguration()">START</button></div>
        
  </div>
  <div fxLayout="column" class="landing-rectangle-large" fxFlex="40" fxLayoutAlign="space-between start">
    <div class="landing-page-box-text">Product Search</div>
    <div class="box-description-text">
      Know the product name or what you’re looking for?
    </div>
    <div class="landing-page_search">
      <i class="fa fa-search fa-lg"></i>
      <input type="text" (keydown.enter)="searchProducts();" [(ngModel)]="searchString" placeholder="" [readonly]="quoteSubmittedStatus">
    </div>
    <div><button mat-flat-button color="accent" (click)="searchProducts()" [disabled]="quoteSubmittedStatus"
        class="textcapitalize cpq-button-text">SEARCH</button></div>
  </div>

  <div fxLayout="column" fxFlex fxLayoutGap="15px">
    <button class="landing-rectangle-small" fxLayoutAlign="center center" (click)="openModal('video')">
      <div class="icon_align">
        <img src="assets/images/video.svg">
        <div class=" landing-vertical-box">Video Tutorials</div>
      </div>
    </button>
    <button class="landing-rectangle-small" fxLayoutAlign="center center" (click)="openModal('faq')">
      <div class="icon_align">
        <img src="assets/images/faqs-1.svg">
        <div class=" landing-vertical-box">Frequently Asked Questions</div>
      </div>
    </button>
  </div>
</div>
<div class="mt-5" *ngIf = "internalUser">
  <div><button mat-flat-button color="accent" class="textcapitalize cpq-button-text float-right" [disabled]="quoteSubmittedStatus"
    (click)="createNewQuote()">CREATE NEW QUOTE</button></div>
  <app-internal-user-quote [quoteSubmittedStatus]="quoteSubmittedStatus" [opportunityName] = "opportunity?.Name" [quote] = "opportunity?.Quotes" (loadCartDetails) = "loadCartDetails($event)"></app-internal-user-quote>
</div>


<!--Cart section-->
<app-cart [quoteId]="quoteID" [opportunityName] = "opportunity?.Name" [opportunityId]='opportunityId' (startNewConfiguration)="startNewConfiguration()" [isSalesForceFlow]="isSalesForceFlow"
  (loadFreshOpportunity)="loadFreshOpportunity($event)" *ngIf="showChildren" [quoteSubmittedStatus]="quoteSubmittedStatus" [isPrimaryQuote]="isPrimaryQuote"></app-cart>

<!--Quote section-->
<app-quote [quoteId]="quoteID" *ngIf="!internalUser && showChildren" (startNewConfiguration)="startNewConfiguration()"></app-quote>