<nav class="navbar second-header body-section navbar-expand-sm justify-content-between">
  <button class="back-btn" *ngIf="isSalesForceFlow" (click)="closeIframe()" title="Back to salesforce">Back</button>
  <div><a class="navbar-brand mt-1" (click)='goToHome()'>
      <img [src]="opcoLogoUrl" alt="logo" class="opcoLogo" />
    </a></div>
  <ul class="navbar-nav ml-auto mt-1" *ngIf="!isSalesForceFlow">
    <li class="nav-item">
      <a class="" (click)='goToHome()'>
        <div class="d-flex flex-column">
          <i class="fa fa-wrench fa_Configure fa-5x"></i>
          <span class="configure">CONFIGURE</span>
        </div>
      </a>
    </li>
  </ul>
</nav>