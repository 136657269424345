import { Component, Inject, SecurityContext } from '@angular/core';
import { MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


@Component({
  selector: 'app-iframe-dialog',
  templateUrl: './iframe-modal.component.html',
  styleUrls: ['./iframe-modal.component.scss']
})
export class IframeDialogComponent {
  title = 'Popup Content';
  iframeUrl: SafeResourceUrl;
  dataUrl: string;
  
  constructor(
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<IframeDialogComponent>
  ) {
    /* Bypassing security is a work around to get TWG content loaded
     * into the modal dialog. It is considered safe as the only URLs submitted
     * are hard-coded, however this should be replaced as soon as possible.
     */
    // TODO: Remove security bypass on modal urls    
    this.dataUrl = data?.url;
    // Sanitize the URL using Angular's built-in sanitize method
    this.iframeUrl = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.dataUrl);
  }
  closeModel() {
    this.dialogRef.close();
  }
}
